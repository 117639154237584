import React from "react";
import { useQuery } from "@apollo/client/react";
import CategorySlider from "../../components/general/category-posts-slider";
import Hero from "../../components/general/hero";
import { GET_BLOG_POSTS, GET_CATEGORIES } from "../../data/data";

const Home = () => {
  const blogImgSize = "small";
  const sliderImgSize = "big";
  const { loading, error, data } = useQuery(GET_BLOG_POSTS, {
    variables: { blogImgSize, sliderImgSize },
  });

  const categoriesQuery = useQuery(GET_CATEGORIES);
  const categoriesData = categoriesQuery.data;

  if (loading) return;
  if (error) return;

  const { blogEntries, blogSliderEntries } = data;

  return (
    <>
      <Hero data={blogSliderEntries} micrositePath="schlafapnoe"/>
      {categoriesData &&
        categoriesData.categories.map((category, index) => {
          const {
            slug,
            categoryPosition,
            categorySubscribeButton,
            categoryColor,
          } = category;

          const categoryPosts = blogEntries.filter(
            (blog) => slug === blog.blogCategory[0].slug
          );

          return (
            <>
              {categoryPosts && (
                <CategorySlider
                  key={index}
                  data={categoryPosts}
                  background={categoryColor}
                  position={categoryPosition}
                  activeButton={categorySubscribeButton}
                />
              )}
            </>
          );
        })}
    </>
  );
};

export default Home;
