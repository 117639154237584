export const LineHeightCalc = (lineHeight, fontSize) => {
  const CalculatedLH = lineHeight / fontSize;
  return CalculatedLH;
};

export function getGlobalSelectedData(data, type) {
  return data.globalSets.find((element) => element.__typename === type);
}

export function getImageHostSrc(elementImg) {
  const postImage = elementImg ? elementImg[0]?.url : false;
  const imgSource = postImage ? "https://" + process.env.REACT_APP_HOST + postImage : "";
  return imgSource;
}
