import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { BlockInformationContainer, InformationList } from "./layouts";
import {
  Flex,
  Container,
  SubtitleMedium,
  TransparentTitle,
} from "../../../layouts";
import Icon from "../../../assets/icons/icon";
import { CategorySliderButton } from "../../general/category-posts-slider/layouts";

const MiniRichtextText = ({ data }) => {
  return (
    <div className="richtext" dangerouslySetInnerHTML={{ __html: data }} />
  );
};

const BlockInformation = ({ data, activeButton, micrositeData }) => {
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  const { infoTitle = "", textLeft = "", textRight = "" } = data;

  return (
    <Container>
      <Flex className="flex-wrap">
        {activeButton && (
          <div className="w-30 mb-lg-20 w-lg-25 pr-15 pl-lg-0 pr-lg-0 d-flex align-center">
            <CategorySliderButton>
              <Icon icon="sliderButtonShape" />
              <SubtitleMedium className="text-center">
                Jetzt Konkakt aufnehmen
              </SubtitleMedium>
            </CategorySliderButton>
          </div>
        )}
        <BlockInformationContainer
          ref={ref}
          className={`${animate ? "io io-active" : "io"} ${
            activeButton ? "block-information-button" : "block-information"
          }`}
        >
          <div className="p-absolute-fixed index-negative-1">
            <Icon icon={`${micrositeData.shade}Shape`} />
          </div>
          <div className="w-half w-sm-full pr-sm-0 mb-sm-20 pr-20">
            {infoTitle && (
              <TransparentTitle black className="pb-30">
                {infoTitle}
              </TransparentTitle>
            )}
            {React.createElement(MiniRichtextText, {
              data: textLeft,
            })}
          </div>
          <div className="w-half w-sm-full pl-sm-0 pl-20">
            <InformationList className="pb-30">
              {React.createElement(MiniRichtextText, {
                data: textRight,
              })}
            </InformationList>
          </div>
        </BlockInformationContainer>
      </Flex>
    </Container>
  );
};

export default BlockInformation;
