import React from "react";
import {
  Container,
  TransparentSubTitle,
  Paragraph,
  PrimaryButton,
  MediumParagraph,
} from "../../../layouts";
import { AppointmentCard } from "./layouts";

const AppointmentCards = ({ data }) => {
  const events = data && data.events && data.events;
  const buttonText = data && data.buttonText && data.buttonText;
  const { displayPlace } = data;
  return (
    <Container>
      <div className="mb-100">
        <TransparentSubTitle black className="mb-30">
          Rauchstopp Online-Gruppenkurses via Teams
        </TransparentSubTitle>
        <div className="row p-relative">
          {events &&
            events.map((event) => {
              const { eventPlace = "", eventTime = "" } = event;
              return (
                <AppointmentCard className="col-3 col-lg-3 col-md-6 mb-md-50 col-sm-12">
                  <div>
                    {eventPlace && displayPlace && (
                      <MediumParagraph className="mb-30">
                        {eventPlace}
                      </MediumParagraph>
                    )}
                    {eventTime && (
                      <Paragraph className="mb-20">{eventTime}</Paragraph>
                    )}
                  </div>
                  <PrimaryButton orange width="100%">
                    {buttonText}
                  </PrimaryButton>
                </AppointmentCard>
              );
            })}
        </div>
      </div>
    </Container>
  );
};

export default AppointmentCards;
