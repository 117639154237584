import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Scrollbar, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import {
  TransparentTitle,
  Container,
  Flex,
  Tag,
  MainTitle,
} from "../../../layouts";
import Image from "../Image";
import {
  HeroContainer,
  HeroItemContainer,
  HeroImage,
  SliderLink,
  HeroTags,
} from "./layouts";
import { getImageHostSrc } from "../../../helpers/functions";

function sentenceSlideFormat(sentence) {
  const splitSentence = sentence.split(" ");
  const firstSentence = splitSentence.slice(0, splitSentence.length / 2);
  const secondSentence = splitSentence.slice(splitSentence.length / 2);

  const firstFinalSentence = firstSentence.join(" ");
  const secondFinalSentence = secondSentence.join(" ");

  return [firstFinalSentence, secondFinalSentence];
}

const Hero = ({ data }) => {
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  if (!data && data === undefined) return;
  
  const sliderData = data[0];
  const staticSlideTitle = sentenceSlideFormat(sliderData?.title);

  return (
    <HeroContainer
      ref={ref}
      className={`pb-100 pb-md-30 ${animate ? "io io-active" : "io"}`}
    >
      <Swiper
        modules={[Scrollbar, EffectFade]}
        effect={"fade"}
        spaceBetween={30}
        scrollbar={{ draggable: true }}
        slidesPerView={1}
      >
        {sliderData.blogSliderPosts.map((slide, index) => {
          const { title, slug, blogCategory, blogHeaderImage, blogPostTags } =
            slide;

          const imgSource = getImageHostSrc(blogHeaderImage);
          const altImg = blogHeaderImage[0].alt;

          return (
            <SwiperSlide key={index} className="pr-sm-15">
              <HeroItemContainer primary>
                <HeroImage className="index-negative-1 float-container-left">
                  <Image
                    source={imgSource}
                    classList="index-negative-1 p-absolute-fixed"
                    alt={altImg}
                  />
                </HeroImage>
                <Container className="index-positive-1">
                  <Flex className="justify-between py-small align-end">
                    <TransparentTitle
                      primary
                      className="pr-100 pr-lg-30 pb-20 w-half d-md-none"
                    >
                      {staticSlideTitle.map((sentence, index) => {
                        return <span key={index}>{sentence}</span>;
                      })}
                    </TransparentTitle>
                    <div className="w-half pr-30 w-md-full hero-description">
                      <SliderLink to={`/posts/${slug}`}>
                        <MainTitle className="pb-20">{title}</MainTitle>
                      </SliderLink>
                      <HeroTags>
                        <Link
                          to={`/category/tag/${blogCategory[0].slug}`}
                          className="text-decoration-none"
                        >
                          <Tag className="mr-20">{blogCategory[0].title}</Tag>
                        </Link>
                        {blogPostTags.map((tag, index) => {
                          return (
                            <Link
                              key={index}
                              to={`/category/tag/${tag.slug}`}
                              className="text-decoration-none"
                            >
                              <Tag className="mr-20">{tag.title}</Tag>
                            </Link>
                          );
                        })}
                      </HeroTags>
                    </div>
                  </Flex>
                </Container>
              </HeroItemContainer>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </HeroContainer>
  );
};

export default Hero;
