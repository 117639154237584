import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_SCHLAFAPNOE_POST } from "../../data/data";
import { Paragraph } from "../../layouts";
import Box from "../../components/general/box";
import Richtext from "../../components/general/richtext";
import TestSteps from "../../components/schlafapnoe/single-test";
import SingleHero from "../../components/general/single-post-hero";
import { SingleContent } from "../../components/general/richtext/layouts";

const Single = () => {
  const postSlug = useParams().slug;

  const {
    data = "",
    loading,
    error,
  } = useQuery(GET_SCHLAFAPNOE_POST, {
    variables: { postSlug },
  });

  if (loading || !data) return;
  if (error) return;

  const singlePostData = data && data.singlePageEntries[0];
  const singlePostContent = singlePostData && singlePostData.pageContent;
  const boxData =
    singlePostData && singlePostData.box[0] && singlePostData.box[0];
  const singleTeaserText = singlePostData.teaserDescription
    ? singlePostData.teaserDescription
    : "";

  const testComponentData =
    singlePostData &&
    singlePostData.test &&
    singlePostData.test.length > 0 &&
    singlePostData.test;

  return (
    <>
      <SingleHero data={singlePostData} />
      <SingleContent>
        {singleTeaserText && <Paragraph>{singleTeaserText}</Paragraph>}
        {testComponentData && <TestSteps data={testComponentData} />}
        {singlePostContent && <Richtext data={singlePostContent} />}
        {boxData && <Box data={boxData} />}
      </SingleContent>
    </>
  );
};

export default Single;
