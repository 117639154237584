import styled from "@emotion/styled";
import { Colors, Transitions } from "../../../assets/variables/variables";
import { Link } from "react-router-dom";

export const CardContainer = styled(Link)`
  flex-direction: flex-column;
  cursor: pointer;
  text-decoration: none;

  h3,
  p {
    transition: 0.2s ${Transitions.$superSmooth};
  }

  &:hover {
    h3,
    p {
      color: ${Colors.$colorPrimaryMedium};
    }

    > div {
      &:before {
        opacity: 1;
      }
    }
  }
`;

export const CardImageContainer = styled.div`
  display: flex;
  position: relative;
  aspect-ratio: 16/9;
  margin-bottom: 20px;
  background-color: ${Colors.$colorGreyMedium};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    transition: 0.4s ${Transitions.$linearSmooth};
  }
`;
