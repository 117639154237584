import React, { useState, useEffect, useContext } from "react";
import { useInView } from "react-intersection-observer";
import { Flex, SubtitleMedium } from "../../../layouts";
import { CategorySliderButton } from "./layouts";
import { ModalContext } from "../../../context/modalContext";
import Icon from "../../../assets/icons/icon";
import Slider from "../slider";

const CategorySlider = ({ data, position, activeButton, background }) => {
  const { setModal } = useContext(ModalContext);
  const [animate, setAnimate] = useState(false);
  const categoryTitle = data[0].blogCategory[0].title;
  const sliderPositions = {
    center: { position: "container" },
    right: { position: "float-container-left", sliderPosition: "ml-auto" },
    left: { position: "float-container-right", sliderPosition: "mr-auto" },
  };

  const { ref, inView } = useInView({
    threshold: 0.25,
  });

  function openModal() {
    setModal(true);
  }

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  return (
    <div ref={ref} className={`${animate ? "io io-active" : "io"}`}>
      <Flex
        className={`${sliderPositions[position].position} p-relative pb-large align-center flex-md-wrap`}
      >
        {activeButton === "true" && (
          <div className="w-20 w-lg-25 w-md-full pr-15 pl-md-15">
            <CategorySliderButton onClick={openModal}>
              <Icon icon="sliderButtonShape" />
              <SubtitleMedium className="text-center">
                Blog abonnieren
              </SubtitleMedium>
            </CategorySliderButton>
          </div>
        )}
        <Slider
          classList={`${
            sliderPositions[position].sliderPosition
              ? sliderPositions[position].sliderPosition
              : ""
          } ${
            position === "center"
              ? "w-full pl-md-0 pr-sm-0"
              : "w-80 w-lg-75 w-md-full"
          } pl-15`}
          data={data}
          background={background}
          categoryTitle={categoryTitle}
          position={position}
        />
      </Flex>
    </div>
  );
};

export default CategorySlider;
