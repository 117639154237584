import React from "react";

const Image = ({ source, classList, alt, style }) => {
  return (
    <img
      className={`object-cover ${classList}`}
      loading="lazy"
      style={style}
      src={source}
      alt={alt}
    />
  );
};

export default Image;
