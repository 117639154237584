import React from "react";
import Icon from "../../../assets/icons/icon";
import { SubtitleMedium } from "../../../layouts";
import { ActiveButtonShape, CategorySliderButton } from "./layouts";

const ActiveButton = ({ position, text, classList }) => {
  return (
    <ActiveButtonShape className={`button-shape-${position} ${classList}`}>
      <div className="w-100 w-lg-25 w-md-full pr-15 pl-md-15">
        <CategorySliderButton>
          <Icon icon="sliderButtonShape" />
          <SubtitleMedium className="text-center pb-0">{text}</SubtitleMedium>
        </CategorySliderButton>
      </div>
    </ActiveButtonShape>
  );
};

export default ActiveButton;
