import React from "react";
import Posts from "../../components/general/posts";
import SearchResult from "../../components/general/search-result";
import { Container } from "../../layouts";

const AllPosts = ({ search }) => {
  return <Container>{search ? <SearchResult /> : <Posts />}</Container>;
};

export default AllPosts;
