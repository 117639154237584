import { ApolloClient } from "@apollo/client/core";
import { InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: "https://" + process.env.REACT_APP_HOST + "/api",
  credentials: "same-origin",
});

const authLink = setContext((_, { header }) => {
  return {
    header: {
      ...header,
      authorization: process.env.REACT_APP_TOKEN,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

export default client;
