import React, { useContext, useState } from "react";
import {
  PrimaryInput,
  PrimaryButton,
  Title,
  Paragraph,
  Flex,
} from "../../../layouts";
import { ModalContainer, Modal } from "./layouts";
import { CloseIcon } from "../header/layouts";
import { ModalContext } from "../../../context/modalContext";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADD_SUBSCRIBE_EMAIL, GET_SUBSCRIBED_EMAILS } from "../../../data/data";
import { InputError } from "../../schlafapnoe/single-test/layouts";

const SuccessContent = () => {
  return <Title className="mb-30">Danke für Ihre Anmeldung!</Title>;
};

const ErrorContent = () => {
  return (
    <>
      <Title className="mb-30">Die Anmeldung ist fehlgeschlagen!</Title>
      <Title>Bitte versuchen Sie es noch einmal.</Title>
    </>
  );
};

const Content = () => {
  const [emailAddress, setEmailAddress] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const { setModal } = useContext(ModalContext);

  const [isEmailRegistred] = useLazyQuery(GET_SUBSCRIBED_EMAILS, {
    variables: { email: emailAddress },
    onCompleted: (data) => {
      if (data.blogSubscriptionEntries.length < 1) {
        subscribeEmail({ variables: { email: emailAddress } })
          .then(() => {
            setModal({ status: true, value: "success" });
          })
          .catch(() => {
            setModal({ status: true, value: "error" });
          });
      } else {
        setModal({ status: true, value: "error" });
      }
    },
  });

  const [subscribeEmail] = useMutation(ADD_SUBSCRIBE_EMAIL);

  function handleSubmit(e) {
    e.preventDefault();
    if (emailAddress) {
      setEmailError(false);
      isEmailRegistred();
    } else {
      setEmailError("Please write your email");
    }
  }

  function handleEmailChange(e) {
    const email = e.target.value;

    if (isValidEmail(e.target.value)) {
      setEmailAddress(email);
    } else {
      setEmailAddress(null);
    }
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <>
      <Title className="mb-30">
        Blog abonnieren und keinen Post mehr verpassen
      </Title>
      <Paragraph>
        Wir liefern neue Erfahrungsberichte, Hintergründe und Neuigkeiten aus
        der Lungenliga Bern direkt in Ihr Mail-Postfach.
      </Paragraph>
      <Paragraph>
        Ihre Daten werden nur zur Versendung dieser Benachrichtigungen genutzt,
        vertraulich behandelt und unter keinen Umständen an Dritte weitergeben.
        Sie können sich jederzeit kostenlos wieder abmelden.
      </Paragraph>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-column">
        {emailError && <InputError>{emailError}</InputError>}
        <PrimaryInput
          width="250px"
          type="Email"
          placeholder="Email-Adresse"
          className="mb-20"
          onChange={handleEmailChange}
          black
        />
        </div>
        <PrimaryButton width="250px">BLOG ABONNIEREN</PrimaryButton>
      </form>
    </>
  );
};

const BlogModal = () => {
  const { modal, setModal } = useContext(ModalContext);
  const modalValue = modal.value ? modal.value : false;
  const errorStatus = modal.value === "error" && { value: modal.value };

  function closeModal() {
    setModal(false);
  }

  const modalContent = {
    success: <SuccessContent />,
    error: <ErrorContent />,
    content: <Content />,
  };

  return (
    <ModalContainer className={modal ? "d-flex" : "d-none"}>
      <Modal {...errorStatus}>
        <CloseIcon modal className="active" onClick={closeModal}>
          <span />
          <span />
        </CloseIcon>
        <Flex className="flex-column">
          {modalValue ? modalContent[modalValue] : modalContent["content"]}
        </Flex>
      </Modal>
    </ModalContainer>
  );
};

export default BlogModal;
