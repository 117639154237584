import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../../assets/icons/icon";
import { ScrollButton } from "./layouts";

const HeroSideNavigation = () => {
 
  return (
    <div className="d-lg-none">
      <ScrollButton>
        <Link to="/search">
          <Icon icon="searchSide" />
        </Link>
      </ScrollButton>

      <ScrollButton search>
        <Link to="/contact">
          <Icon icon="chatSide" />
        </Link>
      </ScrollButton>

      <ScrollButton donate>
        <a href="https://www.lungenliga.ch/de/lungenliga-bern/spenden-mitgliedschaft/spenden.html">
          <Icon icon="donateSide" />
        </a>
      </ScrollButton>
    </div>
  );
};
export default HeroSideNavigation;
