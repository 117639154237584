import styled from "@emotion/styled";
import { Desktop, Mobile } from "../../../assets/mixins/_mixins";
import { Fonts } from "../../../assets/variables/variables";

export const BlockInformationContainer = styled.div`
  position: relative;
  display: flex;
  padding: 100px 0;
  margin-left: auto;

  &.block-information {
    padding-left: calc((100vw - ((100vw - 1310px) / 2)) * 0.2);

    &-button {
      width: 80%;
      padding-left: 25px;

      ${Desktop} {
        width: 100%;
        padding: 0;
      }
    }
  }

  .title {
    font-family: ${Fonts.$medium};
  }

  svg {
    width: 100%;
    height: 100%;
  }

  ${Mobile} {
    padding: 0;
    padding-bottom: 40px;
    flex-direction: column;
  }
`;

export const InformationList = styled.ul`
  margin-left: 20px;

  h4,
  > p {
    margin-left: -20px;
  }

  li {
    list-style: disc;
  }

  ${Mobile} {
    padding-left: 0;

    li {
      margin-left: 5px;
    }
  }
`;
