import React from "react";
import { useQuery } from "@apollo/client/react";
import {
  GET_SCHLAFAPNOE_HOMEPAGE_DATA,
  GET_SCHLAFAPNOE_SLIDER,
  GET_SCHLAFAPNOE_SLIDER_POSTS,
} from "../../data/data";
import Hero from "../../components/schlafapnoe/hero";
import HeroSideNavigation from "../../components/schlafapnoe/hero-side-navigation";
import CategorySlider from "../../components/general/category-posts-slider";
import BlockInformation from "../../components/schlafapnoe/block-information";
import BlockNavigation from "../../components/schlafapnoe/block-navigation";

const Home = () => {
  const { loading, error, data } = useQuery(GET_SCHLAFAPNOE_HOMEPAGE_DATA);
  const categoriesQuery = useQuery(GET_SCHLAFAPNOE_SLIDER_POSTS);
  const teaserQuery = useQuery(GET_SCHLAFAPNOE_SLIDER);
  const sliderData = categoriesQuery.data && categoriesQuery.data.blogEntries;
  const teaseData =
    teaserQuery.data &&
    teaserQuery.data.teaserSliderEntries &&
    teaserQuery.data.teaserSliderEntries;

  if (loading || !data || !teaseData) return;
  if (error) return;
  if (data === undefined) return;

  const { homeEntries } = data;

  const renderComponents = {
    homeBlocks_navigation_BlockType: BlockNavigation,
    homeBlocks_information_BlockType: BlockInformation,
    homeBlocks_slider_BlockType: () => {
      return;
    },
  };

  const micrositeData = {
    title: "Schlafapnoe",
    path: "schalfapnoe",
  };

  return (
    <>
      {teaseData && <Hero data={teaseData} micrositeData={micrositeData} />}
      {homeEntries &&
        homeEntries[0].homeBlocks.map((homepageEntry, index) => {
          const { __typename } = homepageEntry;

          return React.createElement(renderComponents[__typename], {
            key: index,
            data: homepageEntry,
            micrositeData: { shade: "blue", micrositeSlug: "schlafapnoe" },
          });
        })}
      {sliderData && (
        <CategorySlider
          data={sliderData}
          background="blue"
          position="right"
          activeButton="true"
        />
      )}
      <HeroSideNavigation />
    </>
  );
};

export default Home;
