import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Desktop, Laptop, Mobile, Tablet } from "../../../assets/mixins/_mixins";
import { Colors, Fonts, Transitions } from "../../../assets/variables/variables";

export const HeroContainer = styled.div`
  .swiper {
    &-wrapper {
      padding-bottom: 40px;
    }

    &-scrollbar {
      left: 50%;
      width: 200px;
      transform: translateX(-50%);
      height: 2px;

      &-drag {
        background-color: #000;
        border-radius: 3;
        height: 5px;
        top: -1px;
        cursor: pointer;
      }
    }

    &-slide {
      &-active {
        z-index: 1;

        img {
          opacity: 1;
        }

        .hero-description {
          transition: 0.3s;
          opacity: 1;
          transform: translateY(0);
        }
      }

      &-prev {
        .hero-description {
          transform: translateY(30px);
        }
      }

      &-next {
        .hero-description {
          transform: translateY(-30px);
        }
      }

      &-next,
      &-prev {
        .hero-description {
          opacity: 0.6;
        }
      }
    }
  }
`;

export const HeroItemContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 700px;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    ${(props) =>
      props.primary
        ? "width: calc(100% - ((100vw - 1170px) / 2))"
        : "width: 100%"};
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.15625) 57.29%,
      rgba(0, 0, 0, 0) 100%
    );
    border-radius: 0px 100px 0px 0px;
    ${Desktop} {
      width: ${(props) =>
        props.center ? "100%" : "calc(100% - ((100vw - 960px) / 2))"};
    }

    ${Laptop} {
      width: ${(props) =>
        props.center ? "100%" : "calc(100% - ((100vw - 720px) / 2))"};
    }

    ${Tablet} {
      width: ${(props) =>
        props.center ? "100%" : "calc(100% - ((100vw - 576px) / 2))"};
    }
  }

  ${Tablet} {
    height: 580px;
  }

  ${Mobile} {
    height: ${(props) => (props.single ? "370px" : "500px")};

    &:before {
      width: 100%;
    }
  }
`;

export const HeroImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) =>
    props.center ? "100%" : "calc(100% - ((100vw - 1170px) / 2))"};
  height: 100%;

  img {
    opacity: ${(props) => (props.single ? "1" : "0.5")};
    transition: 0.6s ${Transitions.$linearSmooth};
    border-radius: 0 100px 0 0;
  }

  ${Desktop} {
    width: ${(props) =>
      props.center ? "100%" : "calc(100% - ((100vw - 960px) / 2))"};
  }

  ${Laptop} {
    width: ${(props) =>
      props.center ? "100%" : "calc(100% - ((100vw - 720px) / 2))"};
  }

  ${Tablet} {
    width: ${(props) =>
      props.center ? "100%" : "calc(100% - ((100vw - 576px) / 2))"};

    img {
      object-position: left;
    }
  }

  ${Mobile} {
    width: 100%;
  }
`;

export const SliderLink = styled(Link)`
  h3 {
    transition: 0.3s ${Transitions.$superSmooth};
  }

  &:hover {
    h3,
    p {
      color: ${Colors.$colorPrimaryLight};
    }
  }
`;

export const HeroSubTag = styled.div`
  padding-bottom: 5px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;

  p {
    color: #fff;
    font-family: ${Fonts.$light};
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    background-color: #fff;
  }
`;
