import styled from "@emotion/styled";
import { Fonts } from "../../../assets/variables/variables";

export const PaginateString = styled.p`
  display: inline-block;
  position: relative;
  padding: 2px;
  line-height: 1;
  font-size: 18px;
  user-select: none;
  color: #000;
  font-family: ${Fonts.$light};

  &:hover {
    span,
    button {
      text-decoration: underline;
    }
  }

  span {
    display: inline-block;
    position: relative;
    font-weight: bold;
  }

  &.active {
    span {
      color: #000;
    }
  }
`;

export const PrevNext = styled.button`
  font-size: 18px;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

export const PaginateSign = styled.div`
  padding: 0 10px;
`
