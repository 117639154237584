import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Paragraph, Title } from "../../../layouts";
import { CardContainer, CardImageContainer } from "./layouts";
import { getImageHostSrc } from "../../../helpers/functions";
import Image from "../Image";

const CardAnimation = ({ data, classList }) => {
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.22,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  return (
    <CardContainer
      to={`/posts/${data.slug}`}
      ref={ref}
      className={`${classList ? classList : ""} ${
        animate ? "io io-active" : "io"
      }`}
    >
      <CardContent data={data} />
    </CardContainer>
  );
};

const CardStatic = ({ data, classList }) => {
  return (
    <CardContainer
      to={`/posts/${data.slug}`}
      className={`${classList ? classList : ""}`}
    >
      <CardContent data={data} />
    </CardContainer>
  );
};

const CardContent = ({ data }) => {
  const {
    title = "Title",
    blogTeaserText = "",
    blogHeaderImage = false,
  } = data;

  const imgSource = getImageHostSrc(blogHeaderImage);

  return (
    <>
      <CardImageContainer>
        <Image
          source={`${imgSource}`}
          alt="post-image"
          classList="w-full h-full"
        />
      </CardImageContainer>
      <Title hypens className="mb-30">
        {title}
      </Title>
      <Paragraph hypens>{blogTeaserText}</Paragraph>
    </>
  );
};

const Card = ({ data, classList, animation }) => {
  return (
    <>
      {animation ? (
        <CardAnimation data={data} classList={classList} />
      ) : (
        <CardStatic data={data} classList={classList} />
      )}
    </>
  );
};

export default Card;
