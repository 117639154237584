import styled from "@emotion/styled";
import { Tablet } from "../../assets/mixins/_mixins";
import { Colors, Fonts } from "../../assets/variables/variables";

export const ContactFormContainer = styled.div`
  max-width: 640px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 80px;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: 0;
  left: 15px;
  color: #fff;
  padding: 1px 10px;
  background-color: ${Colors.$colorError};
  font-family: ${Fonts.$regular};
  font-weight: 100;
  font-size: 16px;
  transform: translateY(50%);
`;

export const ErrorMessageInput = styled.span`
  position: absolute;
  height: 100%;
  width: 4px;
  left: 0;
  z-index: 1;
  background-color: red;
`;

export const DatePickerButton = styled.button`
  width: fit-content;
  border: none;
  background: transparent;
  display: inline-block;
`;

export const EventDatePicker = styled.div`
  position: absolute;
  left: 55px;

  ${Tablet} {
    left: -100%;
    bottom: 55px;
  }
`;
