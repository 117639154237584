import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useQuery } from "@apollo/client/react";
import "../../../assets/scss/style.scss";
import "react-datepicker/dist/react-datepicker.css";
import TagManager from "react-gtm-module";
import {
  Container,
  Flex,
  PrimaryInput,
  SmallParagraph,
  TitleTag,
} from "../../../layouts";
import Logo from "../logo";
import {
  Seperator,
  CloseIcon,
  Navigation,
  HeaderBurger,
  SearchBarIcon,
  NavigationLink,
  NavigationLinkExt,
  SearchBarHeader,
  NavigationMobile,
  MobileHeaderIcons,
  LanguageNavigation,
  HeaderLogoContainer,
  SearchBarHeaderMobile,
  NavigationMobileSearchBar,
} from "./layouts";
import Icon from "../../../assets/icons/icon";
import { GET_GLOBAL_SET_DATA } from "../../../data/data";
import { getGlobalSelectedData } from "../../../helpers/functions";

const SeperatorBlock = ({ mobileScreen }) => {
  return <>{mobileScreen ? <Seperator mobile /> : <Seperator />}</>;
};

const CategoryBlock = ({ data, mobileScreen, onNavigationChange }) => {
  function toggleHeaderFunction(e) {
    onNavigationChange(e);
  }

  return (
    <ul
      className={
        mobileScreen
          ? "pr-15 pl-15 py-small list-style-none"
          : "py-small list-style-none"
      }
    >
      {data.category.map((item, index) => {
        const { id, title, slug } = item;
        const categoriesLength = data.category.length - 1;

        return (
          <li
            key={id}
            className={index === categoriesLength ? "" : "pb-20"}
            onClick={() => toggleHeaderFunction("navigation")}
          >
            <NavigationLink
              to={`/category/${slug}`}
              className="text-decoration-none"
            >
              <TitleTag>{title}</TitleTag>
            </NavigationLink>
          </li>
        );
      })}
    </ul>
  );
};

const PageBlock = ({ data, mobileScreen, onNavigationChange }) => {
  const { page } = data;

  function toggleHeaderFunction(e) {
    onNavigationChange(e);
  }
  return (
    <ul
      className={
        mobileScreen
          ? "pr-15 pl-15 pt-40 list-style-none pl-0"
          : "pt-40 list-style-none pl-0"
      }
    >
      {page &&
        page.map((item) => {
          const { title, slug, id } = item;

          return (
            <li
              key={id}
              className="pb-20"
              onClick={() => toggleHeaderFunction("navigation")}
            >
              <NavigationLink to={slug}>
                <TitleTag>{title}</TitleTag>
              </NavigationLink>
            </li>
          );
        })}
    </ul>
  );
};

const UrlBlock = ({ data, mobileScreen, onNavigationChange }) => {
  const { label, customUrl, id } = data;

  function toggleHeaderFunction(e) {
    onNavigationChange(e);
  }

  return (
    <ul
      className={
        mobileScreen
          ? "pr-15 pl-15 pt-40 list-style-none pl-0"
          : "pt-40 list-style-none pl-0"
      }
    >
      <li
        key={id}
        className="pb-20"
        onClick={() => toggleHeaderFunction("navigation")}
      >
        <NavigationLinkExt href={customUrl}>
          <TitleTag>{label}</TitleTag>
        </NavigationLinkExt>
      </li>
    </ul>
  );
};

const Header = ({ microsite }) => {
  const [intialLoad, setIntialLoad] = useState(false);
  const { pathname } = useLocation();

  const tagManagerBlog = {
    gtmId: process.env.REACT_APP_GTM_BLOG,
  };

  const [menuStatus, setMenuStatus] = useState({
    navigation: false,
    searchBar: false,
  });

  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.22,
  });

  useEffect(() => {
    TagManager.initialize(tagManagerBlog);
  }, []);

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  useEffect(() => {
    if (intialLoad) {
      if (menuStatus.navigation === true && document.body.clientWidth < 767) {
        document.body.style.overflowY = "hidden";
        document.documentElement.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "unset";
        document.documentElement.style.overflowY = "unset";
      }
    }
    return;
  }, [menuStatus]);

  function toggleHeaderFunction(type) {
    const functions = {
      navigation: { ...menuStatus, navigation: !menuStatus.navigation },
      search: { ...menuStatus, searchBar: !menuStatus.searchBar },
    };

    setMenuStatus(functions[type]);
  }

  function openMobileSearchBar() {
    setMenuStatus({
      navigation: !menuStatus.navigation,
      searchBar: !menuStatus.searchBar,
    });
  }

  const { loading, error, data } = useQuery(GET_GLOBAL_SET_DATA);

  if (pathname === "/print") return;
  if (loading) return;
  if (error) return;

  const headerData = getGlobalSelectedData(data, "header_GlobalSet");
  const { headerIcon, headerLabel, headerMenu } = headerData;
  const navigationBlocks = {
    headerMenu_category_BlockType: CategoryBlock,
    headerMenu_divider_BlockType: SeperatorBlock,
    headerMenu_page_BlockType: PageBlock,
    headerMenu_url_BlockType: UrlBlock,
  };

  console.log(data, "blog data");
  return (
    <Container>
      <Flex className="justify-between align-end py-small pr-100 pr-sm-45">
        <HeaderLogoContainer
          ref={ref}
          className={`${
            animate
              ? "io-header io-header-left io-active"
              : "io-header io-header-left"
          }`}
        >
          <Link
            to="/"
            className="w-full d-inline-block p-relative text-decoration-none"
          >
            {microsite ? (
              <Logo source={headerIcon} />
            ) : (
              <Logo source={headerIcon} text={headerLabel} />
            )}
          </Link>
        </HeaderLogoContainer>
        <Flex
          ref={ref}
          className={`${
            animate
              ? "io-header io-header-right io-active"
              : "io-header io-header-right"
          } index-positive-1`}
        >
          {/* This part will be active after we update the language content  */}
          {/* <LanguageNavigation className="pr-30 pr-lg-15 d-sm-none">
            <SmallParagraph className="active">DE</SmallParagraph>
            <span>/</span>
            <SmallParagraph>FR</SmallParagraph>
          </LanguageNavigation> */}
          <div className="pr-30 pr-lg-15 p-relative d-sm-none">
            <SearchBarHeader onClick={() => toggleHeaderFunction("search")}>
              <Icon icon="search" className="header-search-icon" />
            </SearchBarHeader>
            <Navigation
              search
              className={`${menuStatus.searchBar ? "active" : ""}`}
            >
              <Flex className="justify-end pr-100">
                <CloseIcon
                  onClick={() => toggleHeaderFunction("search")}
                  className={menuStatus.searchBar ? "active" : ""}
                >
                  <span />
                  <span />
                </CloseIcon>
              </Flex>
              <div className="p-relative w-full">
                <form action="/search" method="get">
                  <PrimaryInput
                    type="text"
                    name="s"
                    placeholder="Suche"
                    className="w-full"
                  />
                  <SearchBarIcon type="submit">
                    <Icon icon="search" />
                  </SearchBarIcon>
                </form>
              </div>
            </Navigation>
          </div>
          <HeaderBurger
            onClick={() => toggleHeaderFunction("navigation")}
            className={menuStatus.navigation ? "active" : ""}
          >
            <span />
            <span />
            <span />
          </HeaderBurger>
          <Navigation
            className={`navigation ${menuStatus.navigation ? "active" : ""}`}
          >
            <Seperator />
            {headerMenu &&
              headerMenu.map((block, index) => {
                const { __typename } = block;

                return React.createElement(navigationBlocks[__typename], {
                  key: index,
                  data: block,
                  onNavigationChange: toggleHeaderFunction,
                });
              })}
          </Navigation>
        </Flex>
      </Flex>
      <NavigationMobile
        className={`navigation ${menuStatus.navigation ? "active" : ""}`}
      >
        <MobileHeaderIcons>
          {/* This part will be active after we update the language content  */}
          {/* <LanguageNavigation mobile>
            <SmallParagraph className="active">DE</SmallParagraph>
            <span>/</span>
            <SmallParagraph>FR</SmallParagraph>
          </LanguageNavigation> */}
          <Flex className="ml-15 cursor-pointer" onClick={openMobileSearchBar}>
            <Icon icon="search" className="header-search-icon" />
          </Flex>
          <CloseIcon
            mobile
            onClick={() => toggleHeaderFunction("navigation")}
            className={`ml-15 ${menuStatus.navigation ? "active" : ""}`}
          >
            <span />
            <span />
          </CloseIcon>
        </MobileHeaderIcons>
        <Seperator mobile />
        {headerMenu &&
          headerMenu.map((block, index) => {
            const { __typename } = block;

            return React.createElement(navigationBlocks[__typename], {
              key: index,
              data: block,
              mobileScreen: true,
              onNavigationChange: toggleHeaderFunction,
            });
          })}
      </NavigationMobile>
      <SearchBarHeaderMobile
        className={`${menuStatus.searchBar ? "active" : ""}`}
      >
        <Flex className="justify-end">
          <CloseIcon
            mobile
            mobileSearch
            onClick={() => toggleHeaderFunction("search")}
            className={menuStatus.searchBar ? "active" : ""}
          >
            <span />
            <span />
          </CloseIcon>
        </Flex>
        <NavigationMobileSearchBar
          className={`${menuStatus.searchBar ? "active" : ""}`}
        >
          <div className="p-relative w-full">
            <PrimaryInput
              type="Search"
              placeholder="Suche"
              className="w-full"
            />
            <SearchBarIcon>
              <Icon icon="search" />
            </SearchBarIcon>
          </div>
        </NavigationMobileSearchBar>
      </SearchBarHeaderMobile>
    </Container>
  );
};

export default Header;
