import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  Container,
  TransparentTitle,
  TermsConditionsConatiner,
} from "../../layouts";

const Impressum = () => {
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  return (
    <Container>
      <TermsConditionsConatiner
        ref={ref}
        className={`${animate ? "io io-active" : "io"}`}
      >
        <TransparentTitle black className="pb-40 pb-sm-20">
          Impressum
        </TransparentTitle>
        <h4>Inhalt und Redaktion</h4>
        <p>
          Lungenliga Bern
          <br />
          Chutzenstrasse 10
          <br />
          3007 Bern
        </p>
        <p>
          Tel.: +41 31 300 26 26
          <br />
          Fax: +41 31 300 26 25
          <br />
          E-Mail:
          <a href="mailto:info@lungenliga-be.ch">info@lungenliga-be.ch</a>
        </p>

        <h4>
          Konzeption, Usability, Design, Technische Umsetzung und Entwicklung
        </h4>

        <p>
          Maxomedia AG, Zinggstrasse 1, 3007 Bern
          <br />
          <a href="https://www.maxomedia.ch">maxomedia.ch</a>
        </p>
      </TermsConditionsConatiner>
    </Container>
  );
};

export default Impressum;
