import React, { useState, useEffect } from "react";
import "../../../assets/scss/style.scss";
import { useInView } from "react-intersection-observer";
import { useQuery } from "@apollo/client/react";
import { GET_SCHLAFAPNOE_HEADER } from "../../../data/data";
import { getGlobalSelectedData } from "../../../helpers/functions";
import { Container, Flex, PrimaryInput, TitleTag } from "../../../layouts";
import Logo from "../../general/logo";
import {
  Seperator,
  CloseIcon,
  Navigation,
  HeaderBurger,
  SearchBarIcon,
  NavigationLink,
  SearchBarHeader,
  NavigationMobile,
  MobileHeaderIcons,
  LanguageNavigation,
  HeaderLogoContainer,
  SearchBarHeaderMobile,
  NavigationMobileSearchBar,
  NavigationDropdown,
  HeaderNavTitle,
} from "./layouts";
import Icon from "../../../assets/icons/icon";
import { Link, useLocation } from "react-router-dom";

const DropdownTitleBlock = ({ data, mobileScreen, onNavigationChange }) => {
  const [navigationLinkStatus, setNavigationLinkStatus] = useState(true);

  function toggleHeaderFunction(e) {
    onNavigationChange(e);
  }

  function toggleDropdownLink() {
    setNavigationLinkStatus(!navigationLinkStatus);
  }

  return (
    <>
      <NavigationDropdown>
        <Flex className="w-full justify-between align-center">
          <HeaderNavTitle onClick={toggleDropdownLink}>
            {data.label}
          </HeaderNavTitle>
          <Icon icon="dropdownArrow" />
        </Flex>
        <ul
          className={`${
            mobileScreen
              ? "pr-15 pl-15 list-style-none pt-10"
              : "list-style-none pt-10"
          } ${navigationLinkStatus ? "active" : ""}`}
        >
          {data.links.map((item, index) => {
            const { title, slug } = item;
            const linksArraySize = data.links.length - 1;

            return (
              <li
                key={index}
                className={index === linksArraySize ? "" : "pb-10"}
                onClick={() => toggleHeaderFunction("navigation")}
              >
                <NavigationLink
                  to={`/posts/schlafapnoe/${slug}`}
                  className="text-decoration-none"
                >
                  <TitleTag font="16px">{title}</TitleTag>
                </NavigationLink>
              </li>
            );
          })}
        </ul>
      </NavigationDropdown>
      <Seperator />
    </>
  );
};

const LinksBlock = ({ data, mobileScreen, onNavigationChange }) => {
  const internalLink = data.internalLink ? data.internalLink : false;
  const externalLinkData = data.externalLink && data.label && data;

  function toggleHeaderFunction(e) {
    onNavigationChange(e);
  }

  return (
    <ul className="pt-20 list-style-none pr-0 pl-0">
      {internalLink ? (
        internalLink.map((item, index) => {
          const { title, slug } = item;

          return (
            <li key={index} onClick={() => toggleHeaderFunction("navigation")}>
              <NavigationLink to={slug}>
                <TitleTag>{title}</TitleTag>
              </NavigationLink>
            </li>
          );
        })
      ) : (
        <li onClick={() => toggleHeaderFunction("navigation")}>
          <a href={externalLinkData.externalLink}>
            <TitleTag>{externalLinkData.label}</TitleTag>
          </a>
        </li>
      )}
    </ul>
  );
};

const Header = () => {
  const { pathname } = useLocation();

  const [menuStatus, setMenuStatus] = useState({
    navigation: false,
    searchBar: false,
  });

  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.22,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  useEffect(() => {
    if (menuStatus.navigation === true && document.body.clientWidth < 767) {
      document.body.style.overflowY = "hidden";
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
      document.documentElement.style.overflowY = "unset";
    }
  }, [menuStatus]);

  function toggleHeaderFunction(type) {
    const functions = {
      navigation: { ...menuStatus, navigation: !menuStatus.navigation },
      search: { ...menuStatus, searchBar: !menuStatus.searchBar },
    };

    setMenuStatus(functions[type]);
  }

  function openMobileSearchBar() {
    setMenuStatus({
      navigation: !menuStatus.navigation,
      searchBar: !menuStatus.searchBar,
    });
  }

  const { loading, error, data } = useQuery(GET_SCHLAFAPNOE_HEADER);

  if (pathname === "/print") return;
  if (loading) return;
  if (error) return;

  const headerData = getGlobalSelectedData(data, "headerSchlafapnoe_GlobalSet");
  const { headerIcon, headerLabel, headerSchlafapnoeMenu } = headerData;

  const navigationBlocks = {
    headerSchlafapnoeMenu_dropdown_BlockType: DropdownTitleBlock,
    headerSchlafapnoeMenu_linkInternal_BlockType: LinksBlock,
    headerSchlafapnoeMenu_linkExternal_BlockType: LinksBlock,
  };

  return (
    <Container>
      <Flex className="justify-between align-end py-small pr-100 pr-sm-45">
        <HeaderLogoContainer
          ref={ref}
          className={`${
            animate
              ? "io-header io-header-left io-active"
              : "io-header io-header-left"
          }`}
        >
          <Link
            to="/"
            className="w-full d-inline-block p-relative text-decoration-none"
          >
            <Logo source={headerIcon} />
          </Link>
        </HeaderLogoContainer>
        <Flex
          ref={ref}
          className={`${
            animate
              ? "io-header io-header-right io-active"
              : "io-header io-header-right"
          } index-positive-1`}
        >
          {/* This part will be active after we update the language content  */}
          {/* <LanguageNavigation className="pr-30 pr-lg-15 d-sm-none">
            <SmallParagraph className="active">DE</SmallParagraph>
            <span>/</span>
            <SmallParagraph>FR</SmallParagraph>
          </LanguageNavigation> */}
          <div className="pr-30 pr-lg-15 p-relative d-sm-none">
            <SearchBarHeader onClick={() => toggleHeaderFunction("search")}>
              <Icon icon="search" className="header-search-icon" />
            </SearchBarHeader>
            <Navigation
              search
              className={`${menuStatus.searchBar ? "active" : ""}`}
            >
              <Flex className="justify-end pr-100">
                <CloseIcon
                  onClick={() => toggleHeaderFunction("search")}
                  className={menuStatus.searchBar ? "active" : ""}
                >
                  <span />
                  <span />
                </CloseIcon>
              </Flex>
              <div className="p-relative w-full">
                <form action="/search" method="get">
                  <PrimaryInput
                    type="text"
                    name="s"
                    placeholder="Suche"
                    className="w-full"
                  />
                  <SearchBarIcon type="submit">
                    <Icon icon="search" />
                  </SearchBarIcon>
                </form>
              </div>
            </Navigation>
          </div>
          <HeaderBurger
            onClick={() => toggleHeaderFunction("navigation")}
            className={menuStatus.navigation ? "active" : ""}
          >
            <span />
            <span />
            <span />
          </HeaderBurger>
          <Navigation
            className={`navigation ${menuStatus.navigation ? "active" : ""}`}
          >
            <Seperator />
            {headerSchlafapnoeMenu &&
              headerSchlafapnoeMenu.map((block, index) => {
                const { __typename } = block;

                return React.createElement(navigationBlocks[__typename], {
                  key: index,
                  data: block,
                  onNavigationChange: toggleHeaderFunction,
                });
              })}
          </Navigation>
        </Flex>
      </Flex>
      <NavigationMobile
        className={`navigation ${menuStatus.navigation ? "active" : ""}`}
      >
        <MobileHeaderIcons>
          {/* This part will be active after we update the language content  */}
          {/* <LanguageNavigation mobile>
            <SmallParagraph className="active">DE</SmallParagraph>
            <span>/</span>
            <SmallParagraph>FR</SmallParagraph>
          </LanguageNavigation> */}
          <Flex className="ml-15 cursor-pointer" onClick={openMobileSearchBar}>
            <Icon icon="search" className="header-search-icon" />
          </Flex>
          <CloseIcon
            mobile
            onClick={() => toggleHeaderFunction("navigation")}
            className={`ml-15 ${menuStatus.navigation ? "active" : ""}`}
          >
            <span />
            <span />
          </CloseIcon>
        </MobileHeaderIcons>
        <Seperator mobile />
        {headerSchlafapnoeMenu &&
          headerSchlafapnoeMenu.map((block, index) => {
            const { __typename } = block;

            return React.createElement(navigationBlocks[__typename], {
              key: index,
              data: block,
              mobileScreen: true,
              onNavigationChange: toggleHeaderFunction,
            });
          })}
      </NavigationMobile>
      <SearchBarHeaderMobile
        className={`${menuStatus.searchBar ? "active" : ""}`}
      >
        <Flex className="justify-end">
          <CloseIcon
            mobile
            mobileSearch
            onClick={() => toggleHeaderFunction("search")}
            className={menuStatus.searchBar ? "active" : ""}
          >
            <span />
            <span />
          </CloseIcon>
        </Flex>
        <NavigationMobileSearchBar
          className={`${menuStatus.searchBar ? "active" : ""}`}
        >
          <div className="p-relative w-full">
            <PrimaryInput
              type="Search"
              placeholder="Suche"
              className="w-full"
            />
            <SearchBarIcon>
              <Icon icon="search" />
            </SearchBarIcon>
          </div>
        </NavigationMobileSearchBar>
      </SearchBarHeaderMobile>
    </Container>
  );
};

export default Header;
