import React from "react";
import Icon from "../../../assets/icons/icon";
import { PaginateString, PaginateSign, PrevNext } from "./layouts";

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const totalPages = Math.ceil(totalPosts / postsPerPage);
  const prevDisabled = currentPage === 1;
  const nextDisabled = currentPage === totalPages;

  return (
    <nav className="py-30 d-flex justify-center">
      <PaginateSign>
        <PrevNext
          disabled={prevDisabled}
          onClick={() => paginate(1)}
        >
          <Icon icon="arrowLeft" />
          <Icon icon="arrowLeft" />
        </PrevNext>
      </PaginateSign>
      <PaginateSign>
        <PrevNext
          disabled={prevDisabled}
          onClick={() => paginate(currentPage - 1)}
        >
          <Icon icon="arrowLeft" />
        </PrevNext>
      </PaginateSign>
      <div className="d-flex pr-10 pl-10">
        <PaginateString>Seite</PaginateString>
        <PaginateString>{currentPage}</PaginateString>
        <PaginateString>von</PaginateString>
        <PaginateString>{totalPages}</PaginateString>
      </div>
      <PaginateSign>
        <PrevNext
          disabled={nextDisabled}
          onClick={() => paginate(currentPage + 1)}
        >
          <Icon icon="arrowRight" />
        </PrevNext>
      </PaginateSign>
      <PaginateSign>
        <PrevNext
          disabled={nextDisabled}
          onClick={() => paginate(totalPages)}
        >
          <Icon icon="arrowRight" />
          <Icon icon="arrowRight" />
        </PrevNext>
      </PaginateSign>
    </nav>
  );
};

export default Pagination;
