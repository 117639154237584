import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Card from "../card";
import Icon from "../../../assets/icons/icon";
import { CategoryColorShape } from "./layouts";
import { Flex, Tag, TransparentTitle } from "../../../layouts";
import { GET_CATEGORY_POSTS, GET_TAG_POSTS } from "../../../data/data";
import Pagination from "../pagination";

function GetPostsType() {
  const categorySlug = useParams().category;
  const tagSlug = useParams().tag;

  const paramTypes = categorySlug
    ? { slug: categorySlug, type: "category" }
    : { slug: tagSlug, type: "tag" };

  const postsQueries = {
    category: GET_CATEGORY_POSTS,
    tag: GET_TAG_POSTS,
  };

  const postsSlug = paramTypes.slug;
  const postsQuery = postsQueries[paramTypes.type];
  return { postsSlug, postsQuery };
}

const Posts = () => {
  const { postsQuery, postsSlug } = GetPostsType();
  const [animate, setAnimate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const imgSize = "small";
  const isTag = useParams().tag;

  const { ref, inView } = useInView({
    threshold: 0.22,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  const {
    data = "",
    loading,
    error,
  } = useQuery(postsQuery, {
    variables: { postsSlug, imgSize },
  });

  if (loading) return;
  if (error) return;

  const { blogEntries, categories } = data;

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogEntries.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Flex className="flex-column mb-100 mb-sm-50 mt-70 mt-sm-50 p-relative">
      <div
        ref={ref}
        className={`${
          animate ? "io io-active" : "io"
        } p-relative d-block mb-100 mb-sm-50`}
      >
        <Tag blue className="mr-20 d-inline-block">
          {isTag ? "Tag" : "Kategorie"}
        </Tag>
        <TransparentTitle primary black className="pr-30 w-full">
          {
            isTag ? isTag : categories[0].title
          }
        </TransparentTitle>
      </div>
      {postsSlug === "category" && (
        <>
          <CategoryColorShape className="p-absolute-fixed">
            <Icon icon={`${categories[0].categoryColor}Shape`} />
          </CategoryColorShape>
        </>
      )}
      <Flex className="flex-column">
        <div className="row">
          {currentPosts &&
            currentPosts.map((post) => {
              return (
                <Card
                  animation
                  data={post}
                  key={post.id}
                  classList="col-12 col-md-6 col-lg-3 col-xl-3 mb-100 mb-sm-50"
                />
              );
            })}
        </div>
        {currentPosts && (
          <div className="row d-flex justify-center">
            <Pagination
              loading={loading}
              paginate={paginate}
              totalPosts={blogEntries.length}
              currentPage={currentPage}
              postsPerPage={postsPerPage}
            />
          </div>
        )}
      </Flex>
    </Flex>
  );
};

export default Posts;
