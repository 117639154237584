import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { HeroItemContainer, HeroImage } from "../hero/layouts";
import Image from "../Image";
import {
  Container,
  ContentContainer,
  TransparentTitle,
} from "../../../layouts";
import { getImageHostSrc } from "../../../helpers/functions";

const SingleHero = ({ data }) => {
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  const { title = "Single Post Title" } = data;

  const heroImageData = data.blogHeaderImage
    ? data.blogHeaderImage
    : data.headerImage
    ? data.headerImage
    : null;
  const heroImageSource = heroImageData ? getImageHostSrc(heroImageData) : null;

  return (
    <Container className="p-relative">
      <HeroItemContainer
        single
        center
        ref={ref}
        className={`mb-20 mb-md-50 ${animate ? "io io-active" : "io"}`}
      >
        <HeroImage
          single
          center
          className="index-negative-1 d-flex align-bottom"
        >
          <Image
            source={heroImageSource}
            classList="index-negative-1 p-absolute-fixed"
            alt="Hero-Image"
          />
        </HeroImage>
        <ContentContainer className="pb-40 d-md-none">
          <TransparentTitle primary>{title}</TransparentTitle>
        </ContentContainer>
      </HeroItemContainer>
      <ContentContainer className="pb-40 d-none d-md-block">
        <TransparentTitle black primary>
          {title}
        </TransparentTitle>
      </ContentContainer>
    </Container>
  );
};

export default SingleHero;
