import React from "react";
import Image from "../Image";
import { LogoContainer, LogoText } from "./layouts";
import { getImageHostSrc } from "../../../helpers/functions";

const Logo = ({ source, text }) => {
  const imgSource = getImageHostSrc(source);
  return (
    <LogoContainer className="align-end">
      <Image source={imgSource} alt="logo" classList="main-logo" />
      {text && <LogoText>{text}</LogoText>}
    </LogoContainer>
  );
};

export default Logo;
