import React from "react";
import { PrimaryButton, SecurityInformation } from "../../../layouts";
import ActiveButton from "../../rauchstopp/active-button";

const Box = ({ data, microsite }) => {
  const { header, text } = data;

  const buttonLink = data.buttonLink;
  const buttonLabel = data.buttonLabel;

  return (
    <div className="p-relative">
      <SecurityInformation>
        <h4>{header}</h4>
        <div dangerouslySetInnerHTML={{ __html: text }} />
        {buttonLink && buttonLabel && (
          <PrimaryButton orange className="cursor-pointer">
            <a href={buttonLink} className="text-decoration-none">
              {buttonLabel}
            </a>
          </PrimaryButton>
        )}
      </SecurityInformation>
      {microsite && (
        <ActiveButton
          classList="d-md-none"
          position="top"
          text="Jetzt Konkakt aufnehmen"
        />
      )}
    </div>
  );
};

export default Box;
