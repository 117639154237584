import styled from "@emotion/styled";
import { Mobile } from "../../../assets/mixins/_mixins";
import { Colors } from "../../../assets/variables/variables";

export const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    background-color: #000;
  }
`;

export const Modal = styled.div`
  position: absolute;
  width: 530px;
  min-height: 300px;
  max-height: 80%;
  padding: 70px 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) =>
    props.value ? Colors.$colorError : Colors.$colorPrimaryLight};

  p {
    margin-bottom: 30px;
  }

  ${Mobile} {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
`;
