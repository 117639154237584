import styled from "@emotion/styled";
import { Laptop, Mobile } from "../../../assets/mixins/_mixins";
import { Colors, Transitions } from "../../../assets/variables/variables";

export const FooterContainer = styled.div`
  position: relative;
  padding: 50px 0 80px 0;
  background-color: ${Colors.$colorPrimaryLight};

  ${Mobile} {
    padding: 40px 10% 60px 15px;
  }

  a {
    color: inherit;
    position: relative;

    p {
      position: relative;
      
      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 1px;
        bottom: 0;
        left: 0;
        opacity: 0;
        background-color: #000;
        transition: 0.3s ${Transitions.$superSmooth};
      }

      &:hover {
        &:before {
          opacity: 1;
          width: 45%;
        }
      }
    } 
  }
`;

export const SocialIconContainer = styled.div`
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  margin-right: 30px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: #000;
  transition: 0.2s ${Transitions.$linearSmooth};
  cursor: pointer;

  &:hover {
    background-color: ${Colors.$colorPrimaryMedium};

    svg,
    path {
      fill: #fff;
      stroke: #fff;
    }

    path:nth-of-type(2) {
      fill: ${Colors.$colorPrimaryMedium};
    }
  }

  &:active {
    background-color: #fff;

    svg,
    path {
      fill: #000;
      stroke: #000;
    }

    path:nth-of-type(2) {
      fill: #fff;
    }
  }

  ${Laptop} {
    margin: 0 15px;
  }
`;
