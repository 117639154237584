import styled from "@emotion/styled";
import { Tablet } from "../../../assets/mixins/_mixins";
import { Colors } from "../../../assets/variables/variables";

export const Hero = styled.div`
  background-color: ${(props) =>
    props.reverse === true ? "transparent" : `${Colors.$colorGreyLight}`};
  padding: 80px 0;

  ${Tablet} {
    ${(props) => (props.reverse ? "padding: 40px 0 0 0" : "padding: 40px 0")};
  }
`;

export const HeroContent = styled.div`
  width: 40%;
  display: flex;
  padding: 50px 0;
  ${(props) =>
    props.reverse === true ? "padding-left: 0;" : "padding-right: 30px;"};
  justify-content: center;
  flex-direction: column;

  ${Tablet} {
    width: 100%;
    ${(props) =>
      props.reverse ? "padding: 0; padding-bottom: 20px" : "padding: 0"};
  }
`;

export const HeroImage = styled.div`
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    ${(props) =>
      props.reverse === true ? "padding-left: 30px" : "padding-right: 30px;"};
  }

  ${Tablet} {
    width: 100%;
    padding-bottom: 20px;

    img {
      position: relative;
      aspect-ratio: 13/9;
      padding: 0;
    }
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.reverse === true ? "row-reverse" : "row"};

  ${Tablet} {
    flex-direction: ${(props) =>
      props.reverse === true ? "column-reverse" : "column"};
  }
`;
