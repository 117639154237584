import styled from "@emotion/styled";
import { Mobile } from "../../../assets/mixins/_mixins";
import { Colors, Transitions } from "../../../assets/variables/variables";
import { Link } from "react-router-dom";

export const HeaderLogoContainer = styled.div`
  display: flex;

  ${Mobile} {
    width: calc(100% - 50px);
  }
`;

export const HeaderBurger = styled.div`
  position: relative;
  width: 22px;
  height: 20px;
  padding-left: 20px;
  cursor: pointer;
  user-select: none;

  &:hover {
    span {
      background-color: ${Colors.$colorPrimary}
    }
  }
  
  > span {
    z-index: 11;
    position: absolute;
    left: 0;
    width: 22px;
    height: 3px;
    border-radius: 10px;
    background-color: #000;
    transition: 0.4s ${Transitions.$superSmooth};
    user-select: none;

    &:nth-of-type(1) {
      top: 0px;
    }

    &:nth-of-type(2) {
      top: 8px;
    }

    &:nth-of-type(3) {
      top: 16px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &.active {
    z-index: 11;

    span {
      background-color: #fff;

      &:nth-of-type(1) {
        top: 8px;
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        width: 0;
      }

      &:nth-of-type(3) {
        top: 8px;
        transform: rotate(-45deg);
      }
    }

    ${Mobile} {
      span {
        display: none;
      }
    }
  }
`;

export const MobileHeaderIcons = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  padding-right: 60px;
  justify-content: flex-end;

  svg,
  path,
  stroke {
    fill: #fff;
  }
`;

export const Navigation = styled.div`
  display: none;
  transition: 0.2s ${Transitions.$linearSmooth};
  transform: translateY(-30px);
  padding: 80px 30px 40px 30px;
  ${(props) => props.search && "padding-top: 50px"};
  position: absolute;
  width: 400px;
  right: -100px;
  top: -50px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -2;
  opacity: 0;

  &.active {
    display: inline-block;
    transform: translateY(0);
    z-index: 10;
    opacity: 1;
  }

  ${Mobile} {
    display: none;

    &.active {
      display: none;
      z-index: 12;
    }
  }
`;

export const NavigationMobile = styled.div`
  display: none;
  position: absolute;
  padding: 70px 0;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: -2;
  opacity: 0;
  transition: 0.3s ${Transitions.$superSmooth};
  transform: translateY(-50px);
  background-color: #000;

  &.active {
    transform: translateY(0);
    z-index: 10;
    opacity: 1;
  }

  ${Mobile} {
    display: inline-block;

    &.active {
      z-index: 12;
    }
  }
`;

export const LanguageNavigation = styled.div`
  display: flex;

  span {
    padding: 0 3px;
    color: ${(props) => (props.mobile ? "#fff" : "#000")};
  }

  p {
    color: ${Colors.$colorGrey};

    &.active {
      color: ${(props) => (props.mobile ? "#fff" : "#000")};
    }
  }

  p {
    transition: .3s ${Transitions.$linearSmooth};
    cursor: pointer;
    
    &:hover {
      color: ${Colors.$colorPrimary};
    }
  }
`;

export const Seperator = styled.div`
  display: inline-block;
  position: relative;
  height: 1px;
  margin: 0 auto;
  width: ${(props) => (props.mobile ? "100%;" : "calc(100% - 60px);")};
  background-color: #fff;
`;

export const SearchBarIcon = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
`;

export const SearchBarHeader = styled.div`
  position: relative;
  cursor: pointer;

  svg path {
    transition: .3s ${Transitions.$linearSmooth};
  }

  &:hover {
    svg {
      path {
        fill: ${Colors.$colorPrimary};
      }
    }
  }
`;

export const SearchBarHeaderMobile = styled.div`
  padding: 70px 15px 40px 15px;
  position: absolute;
  background: #000;
  display: none;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  ${Mobile} {
    &.active {
      display: inline-block;
      z-index: 99;
    }
  }
`;

export const CloseIcon = styled.div`
  margin-bottom: 20px;
  width: 22px;
  height: 20px;
  opacity: 0;
  z-index: -2;
  cursor: pointer;
  position: relative;
  ${(props) => props.modal && "position: absolute; top: 30px;"}
  ${(props) =>
    props.mobileSearch &&
    "width: 23px; height: 20px; margin: 0 80px 20px 0; float: right;"}

  > span {
    background-color: ${(props) => props.mobile && "#fff"};

    &:nth-of-type(1) {
      top: 0px;
    }

    &:nth-of-type(2) {
      top: 16px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &.active {
    display: flex;
    opacity: 1;
    z-index: 10;

    span {
      z-index: 11;
      position: absolute;
      left: 0;
      width: 22px;
      height: 3px;
      border-radius: 10px;
      background-color: ${(props) => (props.modal ? "#000" : "#fff")};
      transition: 0.4s ${Transitions.$superSmooth};

      &:nth-of-type(1) {
        top: 8px;
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        top: 8px;
        transform: rotate(-45deg);
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
`;

export const NavigationMobileSearchBar = styled.div`
  width: 100%;
  top: 0;
`;

export const NavigationLink = styled(Link)`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: -4px;
    opacity: 0;
    height: 1px;
    width: 10px;
    background-color: #fff;
    transform: rotate(180deg) translateY(-50%);
    transition: 0.35s ${Transitions.$linearSmooth};
  }

  &:hover {
    &:before {
      left: -14px;
      opacity: 1;
    }
  }
`;

export const NavigationLinkExt = styled.a`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: -4px;
    opacity: 0;
    height: 1px;
    width: 10px;
    background-color: #fff;
    transform: rotate(180deg) translateY(-50%);
    transition: 0.35s ${Transitions.$linearSmooth};
  }

  &:hover {
    &:before {
      left: -14px;
      opacity: 1;
    }
  }
`;
