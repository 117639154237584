import styled from "@emotion/styled";
import { Laptop, Desktop, Tablet } from "../../../assets/mixins/_mixins";
import { Formats } from "../../../assets/variables/variables";

export const ScrollButton = styled.div`
  margin-right: auto;
  position: fixed;
  bottom: 10px;
  right: ${Formats.$containerFloatHalfLarge};
  width: 85px;
  height: 85px;
  float: right;
  z-index: 98;
  cursor: pointer;
  border-radius: 100%;
  transform: translateX(50%);

  svg {
    position: absolute;
    left: -6px;
    top: -5px;
    border-radius: 100%;
  }

  ${Desktop} {
    right: ${Formats.$containerFloatHalfMid};
  }

  ${Laptop} {
    right: ${Formats.$containerFloatHalfSmall};
  }

  ${Tablet} {
    right: 10px;
    bottom: 20px;
  }
`;
