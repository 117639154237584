import React, { useEffect, useState } from "react";
import {
  Container,
  Paragraph,
  PrimaryButton,
  TransparentSubTitle,
} from "../../../layouts";
import { Hero, HeroContainer, HeroContent, HeroImage } from "./layouts";
import Image from "../../../components/general/Image";
import { useInView } from "react-intersection-observer";
import { getImageHostSrc } from "../../../helpers/functions";
import { Link } from "react-router-dom";

const BlockNavigation = ({ data, micrositeData }) => {
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  const { imagePosition, CardTitle, CardSubtitle, buttonLabel, linkedEntry } =
    data;

  const reverseValue = imagePosition === "right" ? true : false;
  const imageUrl =
    linkedEntry &&
    linkedEntry.length > 0 &&
    getImageHostSrc(linkedEntry[0].headerImage);

  const postLink = linkedEntry && linkedEntry.length > 0 && linkedEntry[0].slug;
  return (
    <Hero
      ref={ref}
      reverse={reverseValue}
      className={animate ? "io io-active" : "io"}
    >
      <Container>
        <HeroContainer reverse={reverseValue}>
          <HeroImage reverse={reverseValue}>
            <Image source={imageUrl} classList="w-full" />
          </HeroImage>
          <HeroContent reverse={reverseValue}>
            <TransparentSubTitle black className="pb-30 pb-sm-20">
              {CardTitle}
            </TransparentSubTitle>
            <Paragraph className="pb-30 pb-sm-20">{CardSubtitle}</Paragraph>
            <Link to={`/posts/${micrositeData.micrositeSlug}/${postLink}`} className="w-full">
              <PrimaryButton green className="w-full">{buttonLabel}</PrimaryButton>
            </Link>
          </HeroContent>
        </HeroContainer>
      </Container>
    </Hero>
  );
};

export default BlockNavigation;
