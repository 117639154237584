import React from "react";
import Card from "../card";
import "swiper/css";
import "swiper/css/scrollbar";
import { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SliderContainer, SliderBackground } from "./layouts";
import { TransparentTitle } from "../../../layouts";
import Icon from "../../../assets/icons/icon";

const Slider = ({ classList, data, background, categoryTitle, position }) => {
  const temporarySliderNumber =
    position === "center" || position === "left" ? 4 : 3.5;
  return (
    <SliderContainer className={`${classList}`}>
      <SliderBackground>
        <Icon icon={`${background}Shape`} />
      </SliderBackground>
      <TransparentTitle black className="pb-40 pb-md-30">
        {categoryTitle}
      </TransparentTitle>
      <Swiper
        breakpoints={{
          320: {
            slidesPerView: 1.3,
          },
          567: {
            slidesPerView: 1.4,
          },
          600: {
            slidesPerView: 1.9,
          },
          768: {
            slidesPerView: 2.5,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: temporarySliderNumber,
          },
        }}
        modules={[Scrollbar]}
        spaceBetween={30}
        scrollbar={{ draggable: true }}
      >
        {data.map((post) => {
          return (
            <SwiperSlide key={post.id}>
              <Card data={post} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SliderContainer>
  );
};

export default Slider;
