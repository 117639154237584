import { gql } from "@apollo/client/core";

export const GET_BLOG_POSTS = gql`
  query BlogsQuery($blogImgSize: String!, $sliderImgSize: String!) {
    blogEntries {
      ... on blog_blog_Entry {
        id
        blogTeaserText
        slug
        title
        blogPostTags {
          slug
          title
        }
        blogCategory {
          slug
          title
        }
        blogHeaderImage {
          url(transform: $blogImgSize)
          alt
        }
      }
    }

    blogSliderEntries {
      ... on blogSlider_blogSlider_Entry {
        title
        blogSliderPosts {
          ... on blog_blog_Entry {
            blogHeaderImage {
              url(transform: $sliderImgSize)
              alt
            }
            title
            url
            slug
            blogCategory {
              title
              slug
              ... on blogCategories_Category {
                categoryColor
              }
            }
            blogPostTags {
              title
              slug
            }
          }
        }
      }
    }
  }
`;

export const GET_BLOG_POSTS_SLUG = gql`
  query BlogsQuery {
    blogEntries {
      ... on blog_blog_Entry {
        slug
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query CategoriesQuery {
    categories(group: "blogCategories") {
      ... on blogCategories_Category {
        id
        categoryColor
        categorySubscribeButton
        categoryPosition
        title
        slug
      }
    }
  }
`;

export const GET_SINGLE_POST = gql`
  query SinglePostQuery($postSlug: [String!], $imgSize: String) {
    blogEntries(slug: $postSlug) {
      ... on blog_blog_Entry {
        id
        title
        blogAuthor
        postDate @formatDateTime(format: "m.d.Y")
        blogHeaderImage {
          url(transform: $imgSize)
          alt
        }
        likes
        blogPostTags {
          slug
          title
        }
        blogTeaserText
        box {
          ... on box_box_BlockType {
            id
            text
            header
            buttonLink
            buttonLabel
          }
        }
        blogPostContent {
          ... on blogPostContent_text_BlockType {
            text
          }
          ... on blogPostContent_image_BlockType {
            image {
              url
              alt
            }
          }
          ... on blogPostContent_quote_BlockType {
            quote
            source
          }
        }
        blogCategory {
          title
          slug
          ... on blogCategories_Category {
            categoryColor
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_POSTS = gql`
  query CategoriesQuery($postsSlug: [String!], $imgSize: String) {
    blogEntries(relatedToCategories: { slug: $postsSlug }) {
      ... on blog_blog_Entry {
        id
        blogTeaserText
        slug
        title
        blogPostTags {
          slug
          title
        }

        blogCategory {
          slug
          title
        }

        blogHeaderImage {
          url(transform: $imgSize)
        }
      }
    }
    categories(slug: $postsSlug) {
      id
      slug
      title
      ... on blogCategories_Category {
        categoryColor
      }
    }
  }
`;

export const GET_TAG_POSTS = gql`
  query CategoriesQuery($postsSlug: [String!], $imgSize: String) {
    blogEntries(relatedToTags: { slug: $postsSlug }) {
      ... on blog_blog_Entry {
        id
        blogTeaserText
        slug
        title
        blogPostTags {
          slug
          title
        }
        blogCategory {
          slug
          title
        }
        blogHeaderImage {
          url(transform: $imgSize)
        }
      }
    }
    categories {
      id
      slug
      title
      ... on blogCategories_Category {
        categoryColor
      }
    }
  }
`;

export const GET_GLOBAL_SET_DATA = gql`
  query HeaderDataQuery {
    globalSets {
      ... on header_GlobalSet {
        headerIcon {
          url
        }
        headerLabel
        headerMenu {
          ... on headerMenu_category_BlockType {
            category {
              id
              slug
              title
            }
          }
          ... on headerMenu_page_BlockType {
            page {
              id
              title
              slug
            }
          }
          ... on headerMenu_divider_BlockType {
            id
            title
          }
          ... on headerMenu_url_BlockType {
            id
            label
            customUrl
          }
        }
      }
      ... on socials_GlobalSet {
        name
        socials {
          ... on socials_social_BlockType {
            SocialLink
            socialType
          }
        }
      }
      ... on footer_GlobalSet {
        footerLinks {
          ... on footerLinks_linkExtern_BlockType {
            label
            linkExtern
          }
          ... on footerLinks_linkIntern_BlockType {
            label
            linkIntern {
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_RELATED_POSTS = gql`
  query RelatedPostsQuery($postSlug: [String!], $relatedBlogImageSize: String) {
    blogEntries(limit: 4, relatedToCategories: { slug: $postSlug }) {
      ... on blog_blog_Entry {
        slug
        title
        blogHeaderImage {
          url(transform: $relatedBlogImageSize)
        }
      }
    }
  }
`;

export const GET_SEARCHED_POSTS = gql`
  query SearchedPostsQuery($searchWord: String!, $imgSize: String) {
    blogEntries(search: $searchWord) {
      ... on blog_blog_Entry {
        title
        slug
        id
        blogHeaderImage {
          url(transform: $imgSize)
        }
        blogPostTags {
          slug
          title
        }
      }
    }
  }
`;

export const GET_SUBSCRIBED_EMAILS = gql`
  query SubscribedEmails($email: [String]) {
    blogSubscriptionEntries(title: $email) {
      ... on blogSubscription_default_Entry {
        title
      }
    }
  }
`;

export const ADD_SUBSCRIBE_EMAIL = gql`
  mutation MyMutation($email: String!) {
    save_blogSubscription_default_Entry(
      blogSubscriptionEmail: $email
      authorId: 2844
    ) {
      id
    }
  }
`;

//Schlafapnoe microsite queries
export const GET_SCHLAFAPNOE_SLIDER = gql`
  query Schlafapnoe_slider_posts {
    teaserSliderEntries(site: "schlafapnoeDE") {
      ... on teaserSlider_default_Entry {
        title
        teaserDescription
        teaserLink {
          slug
          title
        }
        headerImage {
          alt
          url(transform: "big")
        }
      }
    }
  }
`;

export const GET_SCHLAFAPNOE_HOMEPAGE_DATA = gql`
  query SchlafHome {
    homeEntries(site: "schlafapnoeDE") {
      ... on home_home_Entry {
        homeBlocks {
          ... on homeBlocks_information_BlockType {
            infoTitle
            textLeft
            textRight
          }
          ... on homeBlocks_navigation_BlockType {
            CardTitle
            CardSubtitle
            buttonLabel
            imagePosition
            linkedEntry {
              ... on singlePage_default_Entry {
                slug
                headerImage {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SCHLAFAPNOE_SLIDER_POSTS = gql`
  query Schlafapnoe_slider_posts {
    blogEntries(
      site: "schlafapnoeDE"
      relatedToCategories: { slug: "schlafapnoe" }
    ) {
      ... on blog_blog_Entry {
        id
        blogTeaserText
        slug
        title
        blogPostTags {
          slug
          title
        }
        blogCategory {
          slug
          title
        }
        blogHeaderImage {
          url(transform: "small")
          alt
        }
      }
    }
  }
`;

export const GET_SCHLAFAPNOE_HEADER = gql`
  query SchlafapnoeHeader {
    globalSets(site: "schlafapnoeDE", handle: "headerSchlafapnoe") {
      ... on headerSchlafapnoe_GlobalSet {
        id
        headerIcon {
          url
        }
        headerSchlafapnoeMenu {
          ... on headerSchlafapnoeMenu_dropdown_BlockType {
            label
            links {
              slug
              title
            }
          }
          ... on headerSchlafapnoeMenu_linkInternal_BlockType {
            internalLink {
              slug
              title
            }
          }
          ... on headerSchlafapnoeMenu_linkExternal_BlockType {
            externalLink
            label
          }
        }
      }
    }
  }
`;

export const GET_SCHLAFAPNOE_POST = gql`
  query SingleSchlafapnoeQuery($postSlug: [String!]) {
    singlePageEntries(site: "schlafapnoeDE", slug: $postSlug) {
      ... on singlePage_default_Entry {
        id
        title
        postDate @formatDateTime(format: "m.d.Y")
        teaserDescription
        headerImage {
          url(transform: "big")
          alt
        }
        box {
          ... on box_box_BlockType {
            id
            text
            header
            buttonLink
            buttonLabel
          }
        }
        pageContent {
          ... on pageContent_text_BlockType {
            text
          }
          ... on pageContent_image_BlockType {
            image {
              url
            }
          }
          ... on pageContent_quote_BlockType {
            id
            quote
            source
          }
        }
        test {
          ... on test_test_BlockType {
            testItems {
              ... on testItems_BlockType {
                id
                testType
                question
                input
                options {
                  ... on options_option_BlockType {
                    text
                  }
                }
              }
            }
            testPrintResult {
              ... on testPrintResult_BlockType {
                printTitle
                buttonText
                formattedtext
                pointsTitle
                pointsUnit
                pointsOptions {
                  ... on pointsOptions_pointInfo_BlockType {
                    lowNumber
                    highNumber
                    optiontext
                  }
                }
                displayBigButton
              }
            }
          }
        }
        testSteps {
          ... on testSteps_testSteps_BlockType {
            stepsTitle
            steps {
              ... on steps_BlockType {
                id
                columnTitle
                columnContent
                buttonLabel
                buttonLink {
                  url
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

//Rauchstopp microsite queries
export const GET_RAUCHSTOPP_SLIDER = gql`
  query Rauchstopp_slider_posts {
    teaserSliderEntries(site: "rauchstoppDE") {
      ... on teaserSlider_default_Entry {
        title
        teaserDescription
        teaserLink {
          slug
          title
        }
        headerImage {
          alt
          url(transform: "big")
        }
      }
    }
  }
`;

export const GET_RAUCHSTOPP_HOMEPAGE_DATA = gql`
  query RauchHome {
    homeEntries(site: "rauchstoppDE") {
      ... on home_home_Entry {
        homeBlocks {
          ... on homeBlocks_information_BlockType {
            infoTitle
            textLeft
            textRight
          }
          ... on homeBlocks_slider_BlockType {
            id
          }
          ... on homeBlocks_navigation_BlockType {
            CardTitle
            CardSubtitle
            buttonLabel
            imagePosition
            linkedEntry {
              ... on singlePage_default_Entry {
                slug
                headerImage {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_RAUCHSTOPP_SLIDER_POSTS = gql`
  query Schlafapnoe_slider_posts {
    blogEntries(
      site: "rauchstoppDE"
      relatedToCategories: { slug: "rauchstopp" }
    ) {
      ... on blog_blog_Entry {
        id
        blogTeaserText
        slug
        title
        blogPostTags {
          slug
          title
        }
        blogCategory {
          slug
          title
        }
        blogHeaderImage {
          url(transform: "small")
          alt
        }
      }
    }
  }
`;

export const GET_RAUCHSTOPP_HEADER = gql`
  query RauchstoppHeaderDataQuery {
    globalSets(site: "rauchstoppDE", handle: "header") {
      ... on header_GlobalSet {
        headerIcon {
          url
        }
        headerLabel
        headerMenu {
          ... on headerMenu_category_BlockType {
            category {
              id
              slug
              title
            }
          }
          ... on headerMenu_page_BlockType {
            page {
              id
              title
              slug
            }
          }
          ... on headerMenu_divider_BlockType {
            id
            title
          }
          ... on headerMenu_url_BlockType {
            id
            label
            customUrl
          }
        }
      }
    }
  }
`;

export const GET_RAUCHSTOPP_POST = gql`
  query SingleRauchstopQuery($postSlug: [String!]) {
    singlePageEntries(site: "rauchstoppDE", slug: $postSlug) {
      ... on singlePage_default_Entry {
        id
        title
        postDate @formatDateTime(format: "m.d.Y")
        teaserDescription
        box {
          ... on box_box_BlockType {
            id
            text
            header
            buttonLink
            buttonLabel
          }
        }
        pageContent {
          ... on pageContent_text_BlockType {
            text
          }
          ... on pageContent_image_BlockType {
            image {
              url
            }
          }
          ... on pageContent_quote_BlockType {
            id
            quote
            source
          }
        }
        test {
          ... on test_test_BlockType {
            testItems {
              ... on testItems_BlockType {
                id
                testType
                question
                input
                options {
                  ... on options_option_BlockType {
                    text
                  }
                }
              }
            }
            testPrintResult {
              ... on testPrintResult_BlockType {
                printTitle
                buttonText
                formattedtext
                pointsTitle
                pointsUnit
                pointsOptions {
                  ... on pointsOptions_pointInfo_BlockType {
                    lowNumber
                    highNumber
                    optiontext
                  }
                }
                displayBigButton
              }
            }
          }
        }
        testSteps {
          ... on testSteps_testSteps_BlockType {
            stepsTitle
            steps {
              ... on steps_BlockType {
                id
                columnTitle
                columnContent
                buttonLabel
                buttonLink {
                  url
                  slug
                }
              }
            }
          }
        }
        eventBlock {
          ... on eventBlock_eventBlock_BlockType {
            id
            titleText
            displayPlace
            buttonText
            remark
            events {
              ... on event_default_Entry {
                id
                eventPlace
                eventTime
              }
            }
          }
        }
        headerImage {
          url(transform: "big")
        }
      }
    }
  }
`;
