import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_RAUCHSTOPP_POST } from "../../data/data";
import { Paragraph } from "../../layouts";
import Box from "../../components/general/box";
import Richtext from "../../components/general/richtext";
import TestSteps from "../../components/schlafapnoe/single-test";
import SingleHero from "../../components/general/single-post-hero";
import { SingleContent } from "../../components/general/richtext/layouts";
import AppointmentCards from "../../components/rauchstopp/appointments-card";

const Single = () => {
  const postSlug = useParams().slug;

  const {
    data = "",
    loading,
    error,
  } = useQuery(GET_RAUCHSTOPP_POST, {
    variables: { postSlug },
  });

  if (loading) return;
  if (error) return;

  const singlePostData = data && data.singlePageEntries[0];
  const singlePostContent = singlePostData && singlePostData.pageContent;
  const boxData = singlePostData && singlePostData.box[0];
  const blogTeaserText = singlePostData.teaserDescription
    ? singlePostData.teaserDescription
    : "";

  const testComponentData =
    singlePostData &&
    singlePostData.test &&
    singlePostData.test.length > 0 &&
    singlePostData.test;

  const eventBlockData = singlePostData && singlePostData.eventBlock;
  return (
    <>
      <SingleHero data={singlePostData} />
      <SingleContent>
        <Paragraph>{blogTeaserText}</Paragraph>
      </SingleContent>
      {eventBlockData &&
        eventBlockData?.map((block, index) => {
          return <AppointmentCards key={index} data={block} />;
        })}
      <SingleContent>
        {testComponentData && <TestSteps data={testComponentData} />}
        <Richtext data={singlePostContent} microsite />
        {boxData && <Box data={boxData} microsite />}
      </SingleContent>
    </>
  );
};

export default Single;
