import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import Icon from "../../assets/icons/icon";
import {
  Container,
  Flex,
  TransparentTitle,
  Paragraph,
  PrimaryButton,
} from "../../layouts";
import { ErrorContainer } from "./pagesLayouts";

const Error = () => {
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  return (
    <Container>
      <ErrorContainer
        ref={ref}
        className={`justify-center h-full ${animate ? "io io-active" : "io"}`}
      >
        <div className="w-70 w-lg-full d-flex justify-center flex-column">
          <Flex className="flex-md-column">
            <Flex className="align-center justify-sm-center pr-50 pr-sm-0 pb-sm-20">
              <Icon icon="error" />
            </Flex>
            <Flex className="flex-column">
              <TransparentTitle primary black className="pb-40">
                Uhhhh, wir konnten die gewünschte Seite nicht finden!
              </TransparentTitle>
              <Paragraph className="mb-40">
                Nutzen Sie die kurze Pause um tief durchzuatmen und Ihre Lungen
                mit Luft zu füllen. Hier gehts danach weiter...
              </Paragraph>
              <Link to="/">
                <PrimaryButton width="400px">zur Startseite</PrimaryButton>
              </Link>
            </Flex>
          </Flex>
        </div>
      </ErrorContainer>
    </Container>
  );
};

export default Error;
