import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { Scrollbar, EffectFade } from "swiper";
import {
  Flex,
  Container,
  MainTitle,
  Paragraph,
  TransparentTitle,
} from "../../../layouts";
import Image from "../../../components/general/Image";
import {
  HeroImage,
  SliderLink,
  HeroSubTag,
  HeroContainer,
  HeroItemContainer,
} from "./layouts";
import { getImageHostSrc } from "../../../helpers/functions";

const Hero = ({ data, micrositeData }) => {
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  return (
    <HeroContainer
      ref={ref}
      className={`pb-100 pb-md-30 ${animate ? "io io-active" : "io"}`}
    >
      <Swiper
        modules={[Scrollbar, EffectFade]}
        effect={"fade"}
        spaceBetween={30}
        scrollbar={{ draggable: true }}
        slidesPerView={1}
      >
        {data.map((slide, index) => {
          const { title, teaserLink, headerImage } = slide;
          const teaserUrl =
            teaserLink && teaserLink.length > 0 && teaserLink[0].title;
          const imgSource = getImageHostSrc(headerImage);
          const altImg = headerImage[0].alt;

          return (
            <SwiperSlide key={index} className="pr-sm-15">
              <HeroItemContainer primary>
                <HeroImage className="index-negative-1 float-container-left">
                  <Image
                    source={imgSource}
                    classList="index-negative-1 p-absolute-fixed"
                    alt={altImg}
                  />
                </HeroImage>
                <Container className="index-positive-1">
                  <Flex className="justify-between py-small align-end">
                    <TransparentTitle
                      primary
                      className="pr-100 pr-lg-30 pb-20 w-half d-md-none"
                    >
                      {/* This part will be replaced after we take API  */}
                      {/* {staticSlideTitle.map((sentence) => {
                        return <span>{sentence}</span>;
                      })} */}
                      <span>{micrositeData.title}</span>
                    </TransparentTitle>
                    <div className="w-half pr-30 w-md-full hero-description">
                      {micrositeData.title === "Schlafapnoe" && (
                        <HeroSubTag>
                          <Paragraph>Symtopme</Paragraph>
                        </HeroSubTag>
                      )}
                      {teaserUrl ? (
                        <SliderLink
                          to={`posts/${micrositeData.path}/${teaserLink[0].title}`}
                        >
                          <MainTitle className="pb-20">{title}</MainTitle>
                        </SliderLink>
                      ) : (
                        <MainTitle className="pb-20">{title}</MainTitle>
                      )}
                    </div>
                  </Flex>
                </Container>
              </HeroItemContainer>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </HeroContainer>
  );
};

export default Hero;
