import React from "react";
import * as Yup from "yup";
import {
  Container,
  Flex,
  Paragraph,
  PrimaryButton,
  PrimaryInput,
  PrimaryTextArea,
  TransparentTitle,
} from "../../layouts";
import {
  ContactFormContainer,
  ErrorMessage,
  ErrorMessageInput,
} from "./pageLayouts";
import { Formik, Form } from "formik";

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  surname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  address: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  postCode: Yup.number()
    .required("Required")
    .positive("Please add positive number")
    .integer("Please add valid number"),
  tel: Yup.number()
    .required("Required")
    .positive("Please add positive number")
    .integer("Please add valid number"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const Contact = () => {
  return (
    <Container>
      <ContactFormContainer>
        <Flex className="p-relative flex-column mb-10 pb-40">
          <TransparentTitle black className="pb-20">
            Kontaktformular
          </TransparentTitle>
          <Paragraph className="pb-20">
            Füllen Sie bitte untenstehendes Formular aus, um mit uns in Kontakt
            zu treten. Felder mit einem * müssen ausgefüllt werden.
          </Paragraph>
          <Paragraph>
            Die Kontaktinformationen werden ausschliesslich für die Bearbeitung
            Ihrer Anfrage verwendet. Datenschutzerklärung
          </Paragraph>
        </Flex>
        <Flex>
          <Formik
            initialValues={{
              name: "",
              surname: "",
              address: "",
              email: "",
              tel: "",
              message: "",
              postCode: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="w-full">
                <Flex className="p-relative flex-column mb-10">
                  <label>Anrede*</label>
                  <Flex>
                    <Flex className="pr-20">
                      <label>
                        <PrimaryInput type="radio" id="gender" name="gender" />
                        Frau
                      </label>
                    </Flex>
                    <Flex>
                      <label>
                        <PrimaryInput type="radio" id="gender" name="gender" />
                        Herr
                      </label>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex className="p-relative flex-column mb-10">
                  <label for="surname">Vorname*</label>
                  <div className="p-relative w-full">
                    {errors.surname && touched.surname && <ErrorMessageInput />}
                    <PrimaryInput
                      black
                      id="surname"
                      type="text"
                      name="surname"
                      className="w-full"
                    />
                  </div>
                  {errors.surname && touched.surname && (
                    <ErrorMessage>{errors.surname}</ErrorMessage>
                  )}
                </Flex>
                <Flex className="p-relative flex-column mb-10">
                  <label for="name">Name*</label>
                  <div className="p-relative w-full">
                    {errors.name && touched.name && <ErrorMessageInput />}
                    <PrimaryInput
                      black
                      id="name"
                      type="text"
                      name="name"
                      className={`w-full ${errors.name ? "error-input" : ""}`}
                    />
                  </div>
                  {errors.name && touched.name && (
                    <ErrorMessage>{errors.name}</ErrorMessage>
                  )}
                </Flex>
                <Flex className="p-relative flex-column mb-10">
                  <label for="address">Adresse</label>
                  <div className="p-relative w-full">
                    {errors.address && touched.address && <ErrorMessageInput />}
                    <PrimaryInput
                      black
                      id="address"
                      type="text"
                      name="address"
                      className="w-full"
                    />
                  </div>
                  {errors.address && touched.address && (
                    <ErrorMessage>{errors.address}</ErrorMessage>
                  )}
                </Flex>
                <Flex className="p-relative flex-column mb-10">
                  <label for="postcode">PLZ/Ort</label>
                  <div className="p-relative w-full">
                    {errors.postCode && touched.postCode && (
                      <ErrorMessageInput />
                    )}
                    <PrimaryInput
                      black
                      id="postcode"
                      type="text"
                      name="postcode"
                      className="w-full"
                    />
                  </div>
                  {errors.postCode && touched.postCode && (
                    <ErrorMessage>{errors.postCode}</ErrorMessage>
                  )}
                </Flex>
                <Flex className="p-relative flex-column mb-10">
                  <label for="email">Email</label>
                  <div className="p-relative w-full">
                    {errors.email && touched.email && <ErrorMessageInput />}
                    <PrimaryInput
                      black
                      id="email"
                      type="email"
                      name="email"
                      className={`w-full ${errors.email ? "error-input" : ""}`}
                    />
                  </div>
                  {errors.email && touched.email && (
                    <ErrorMessage>{errors.email}</ErrorMessage>
                  )}
                </Flex>
                <Flex className="p-relative flex-column mb-10">
                  <label for="tel">Telefon</label>
                  <div className="p-relative w-full">
                    {errors.tel && touched.tel && <ErrorMessageInput />}
                    <PrimaryInput
                      black
                      id="tel"
                      type="text"
                      name="tel"
                      className="w-full"
                    />
                  </div>
                  {errors.tel && touched.tel && (
                    <ErrorMessage>{errors.tel}</ErrorMessage>
                  )}
                </Flex>
                <Flex className="p-relative flex-column mb-40">
                  <label for="tel">Mitteilung</label>
                  <PrimaryTextArea
                    black
                    id="message"
                    type="textarea"
                    name="message"
                    className="w-full"
                    rows="3"
                  />
                  {errors.message && touched.message && (
                    <ErrorMessage>{errors.message}</ErrorMessage>
                  )}
                </Flex>
                <div className="pb-40 d-block">
                  <PrimaryInput
                    black
                    rows="3"
                    id="checkbox"
                    type="checkbox"
                    name="checkbox"
                    className="w-full"
                    width="fit-content"
                  />
                  <label for="checkbox">Bitte kontaktieren Sie mich</label>
                </div>
                <PrimaryButton
                  type="submit"
                  disabled={isSubmitting}
                  className="mb-40"
                  width="400px"
                >
                  Absenden
                </PrimaryButton>
              </Form>
            )}
          </Formik>
        </Flex>
      </ContactFormContainer>
    </Container>
  );
};

export default Contact;
