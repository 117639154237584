import React from "react";
import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import RelatedPosts from "../../components/general/related-posts";
import SingleHero from "../../components/general/single-post-hero";
import { GET_SINGLE_POST, GET_RELATED_POSTS } from "../../data/data";
import {
  LikeContainer,
  SingleContent,
  SingleContentShape,
  SinglePostTags,
  SingleTeaser,
} from "../../components/general/richtext/layouts";
import Richtext from "../../components/general/richtext";
import { Paragraph, Tag } from "../../layouts";
import Box from "../../components/general/box";
import Icon from "../../assets/icons/icon";

const Single = () => {
  const postSlug = useParams().slug;
  const imgSize = "big";
  const {
    data = "",
    loading,
    error,
  } = useQuery(GET_SINGLE_POST, {
    variables: { postSlug, imgSize },
  });

  const singlePostData = data && data.blogEntries[0];
  const singlePostCategory = singlePostData && singlePostData.blogCategory[0];
  const singlePostContent = singlePostData && singlePostData.blogPostContent;
  const singlePostTags = singlePostData && singlePostData.blogPostTags;
  const boxData = singlePostData && singlePostData.box[0];
  const categorySlug = singlePostData && singlePostCategory.slug;
  const relatedBlogImageSize = "small";
  const blogTeaserText = singlePostData.blogTeaserText
    ? singlePostData.blogTeaserText
    : "";
  const relatedQuery = useQuery(GET_RELATED_POSTS, {
    variables: { categorySlug, relatedBlogImageSize },
  });
  const relatedData = relatedQuery.data && relatedQuery.data.blogEntries;

  //Scroll to top
  window.scrollTo(0, 0);

  return (
    <>
      <SingleHero data={singlePostData} />
      <SingleContent>
        {singlePostCategory.categoryColor && (
          <SingleContentShape>
            <Icon icon={`${singlePostCategory.categoryColor}Shape`} />
          </SingleContentShape>
        )}
        <SingleTeaser>
          <Paragraph>
            {blogTeaserText}
            {singlePostData.blogAuthor && (
              <>
                <span className="pl-5 pr-5">|</span>
                <span>{singlePostData.blogAuthor}</span>
              </>
            )}
            <span className="flex">
              <span className="pl-5 pr-5">|</span>
              <span>{singlePostData.postDate}</span>
            </span>
          </Paragraph>
        </SingleTeaser>
        <SinglePostTags>
          <Link
            to={`/category/${singlePostCategory.slug}`}
            className="text-decoration-none"
          >
            <Tag blue className="mr-30">
              {singlePostCategory.title}
            </Tag>
          </Link>
          {singlePostTags &&
            singlePostTags.map((tag, index) => {
              const { title } = tag;
              return (
                <Link
                  key={index}
                  to={`/category/tag/${tag.slug}`}
                  className="text-decoration-none"
                >
                  <Tag black className="mr-30">
                    {title}
                  </Tag>
                </Link>
              );
            })}
          <LikeContainer>
            <Icon icon="heart" />
            <Tag>LIKES</Tag>
          </LikeContainer>
        </SinglePostTags>
        <Richtext data={singlePostContent} />
        {boxData && <Box data={boxData} />}
      </SingleContent>
      {relatedData && <RelatedPosts data={relatedData} />}
    </>
  );
};

export default Single;
