import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Container, TransparentTitle } from "../../../layouts";
import { Flex } from "../../../layouts/index";
import Card from "../card";

const RelatedPosts = ({ data }) => {
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.15,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  return (
    <Container>
      <Flex className="flex-column">
        <TransparentTitle
          black
          ref={ref}
          className={`mb-40 ${animate ? "io io-active" : "io"}`}
        >
          Hier gehts weiter
        </TransparentTitle>
        <div className="row">
          {data.map((post, index) => {
            return (
              <div
                key={index}
                className="col-12 col-md-6 col-lg-3 col-xl-3 mb-100"
              >
                <Card animation data={post} key={post.id} />
              </div>
            );
          })}
        </div>
      </Flex>
    </Container>
  );
};

export default RelatedPosts;
