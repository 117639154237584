import React from "react";
import { useQuery } from "@apollo/client/react";
import {
  GET_RAUCHSTOPP_SLIDER,
  GET_RAUCHSTOPP_SLIDER_POSTS,
  GET_RAUCHSTOPP_HOMEPAGE_DATA,
} from "../../data/data";
import Hero from "../../components/schlafapnoe/hero";
import ScrollNav from "../../components/schlafapnoe/hero-side-navigation";
import CategorySlider from "../../components/general/category-posts-slider";
import BlockInformation from "../../components/schlafapnoe/block-information";
import BlockNavigation from "../../components/schlafapnoe/block-navigation";

const Home = () => {
  const { loading, error, data } = useQuery(GET_RAUCHSTOPP_HOMEPAGE_DATA);
  const categoriesQuery = useQuery(GET_RAUCHSTOPP_SLIDER_POSTS);
  const teaserQuery = useQuery(GET_RAUCHSTOPP_SLIDER);
  const sliderData = categoriesQuery.data && categoriesQuery.data.blogEntries;
  const teaseData = teaserQuery.data && teaserQuery.data.teaserSliderEntries;

  if (loading || !data || !teaseData) return;
  if (error) return;
  if (data === undefined) return;

  const { homeEntries } = data;

  const renderComponents = {
    homeBlocks_navigation_BlockType: BlockNavigation,
    homeBlocks_information_BlockType: BlockInformation,
    homeBlocks_slider_BlockType: CategorySlider,
  };

  const micrositeData = {
    title: "Rauchstopp",
    path: "rauchstopp",
  };

  return (
    <>
      {teaseData && <Hero data={teaseData} micrositeData={micrositeData} />}
      {homeEntries &&
        homeEntries[0].homeBlocks.map((homepageEntry, index) => {
          const { __typename } = homepageEntry;
          const data =
            __typename === "homeBlocks_slider_BlockType"
              ? sliderData
              : homepageEntry;

          return React.createElement(renderComponents[__typename], {
            key: index,
            data: data,
            micrositeData: { shade: "orange", micrositeSlug: "rauchstopp" },
            background: "orange",
            position: "center",
            activeButton: "false",
          });
        })}
      {sliderData && (
        <CategorySlider
          data={sliderData}
          background="blue"
          position="right"
          activeButton="true"
        />
      )}
      <ScrollNav />
    </>
  );
};

export default Home;
