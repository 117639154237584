import styled from "@emotion/styled";
import { Mobile } from "../../../assets/mixins/_mixins";
import { Colors, Fonts } from "../../../assets/variables/variables";

export const SingleTestContainer = styled.div`
  width: 100%;
  padding: 90px 80px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 0px 50px 0px 0px;

  ${Mobile} {
    padding: 30px 40px;
  }
`;

export const SingleTestImage = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.print ? "35%" : "40%")};

  ${Mobile} {
    width: 35%;
  }
`;

export const SingleTestContent = styled.form`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 30px;
  width: ${(props) => (props.print ? "65%" : "60%")};

  ${Mobile} {
    width: 65%;
  }
`;

export const SingleTestContentQuestion = styled.div`
  display: inline-block;

  ${Mobile} {
    width: 100%;
  }
`;

export const SingleTestQuestion = styled.h3`
  font-size: 34px;
  font-family: ${Fonts.$extraLight};
  font-weight: 100;
  padding-bottom: 20px !important;

  ${Mobile} {
    font-size: 25px;
  }
`;

export const SingleTestConfirmButton = styled.button`
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #000 !important;
  background-color: transparent !important;
  cursor: pointer;

  svg {
    path {
      stroke: #000;
    }
  }

  ${Mobile} {
    width: 35px;
    height: 35px;

    svg {
      max-width: 20px;
      max-heigh: 20px;
    }
  }
`;

export const SingleTestConfirmButtonVisual = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  background-color: ${Colors.$colorPrimaryMedium} !important;
  padding: 0 !important;
`;

export const SingleTestContainerPrint = styled.div`
  width: calc(50% - 15px);
  margin-bottom: 80px;
`;

export const StepTestInputText = styled.input`
  max-width: 190px;
  width: 100%;
  padding: 10px 30px;
  background: ${Colors.$colorPrimarySuperLight};
  border: 1px solid #000;
  border-radius: 10px;
  text-align: center;
  font-family: ${Fonts.$regular};
  font-weight: 400;

  ${Mobile} {
    max-width: 105px;
    padding: 5px 25px;
  }
`;

export const StepTestInputCopy = styled.div`
  width: 170px;
  padding: 10px 30px;
  background: ${Colors.$colorPrimarySuperLight};
  border: 1px solid #000;
  border-radius: 10px;
  text-align: center;
  font-family: ${Fonts.$regular};
  font-weight: 400;
`;

export const StepTestInputHolder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  p {
    display: inline-block;
    padding: 0 0 0 10px;
  }

  ${Mobile} {
    flex-wrap: wrap;

    p {
      font-family: ${Fonts.$regular};
    }
  }
`;

export const StepTestInputOption = styled.input`
  accent-color: ${Colors.$colorPrimary};
`;

export const StepTestInputOptionRadioButton = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid
    ${(props) => (props.checked ? Colors.$colorPrimary : "#767676")};
  border-radius: 100px;
  position: relative;

  &:before {
    ${(props) => props.checked && "content: ''"};
    position: absolute;
    top: 1px;
    left: 1px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${Colors.$colorPrimary};
  }
`;

export const InputError = styled.span`
  padding-bottom: 5px !important;
  color: red;
  font-size: 14px;
  font-family: ${Fonts.$light};
`;

export const NextPrevBtn = styled.button`
  background: none;
  border: none;
  padding: 0 5px;
  cursor: pointer;

  &:disabled {
    svg {
      path {
        stroke: ${Colors.$colorGrey};
      }
    }
  }
`;

export const TestPrintContainer = styled.div`
  position: relative;
  display: flex;

  button {
    padding-right: 60px !important;
    padding-left: 60px !important;
    background-color: ${Colors.$colorGreen} !important;

    &:hover {
      background-color: ${Colors.$colorPrimary} !important;
    }
  }
`;
