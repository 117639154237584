import React from "react";
import { getImageHostSrc } from "../../../helpers/functions";
import Image from "../Image";

const ImageRender = ({ data }) => {
  const { image } = data;
  const imgSource = getImageHostSrc(image);

  return <>{<Image source={imgSource} />}</>;
};

const TextRender = ({ data }) => {
  const { text } = data;
  return (
    <>
      <div
        className="richtext-description"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </>
  );
};

const QuoteRender = ({ data }) => {
  const { quote = "", source = "" } = data;
  return (
    <p>
      <q>{quote}</q>
      {source}
    </p>
  );
};

// const VideoRender = () => {
//   return (
//     <>
//       <p>Video Will be implemented later</p>
//     </>
//   );
// };

const Richtext = ({ data, boxData }) => {
  let microsite = true;

  const micrositeBlocks = {
    pageContent_image_BlockType: ImageRender,
    pageContent_text_BlockType: TextRender,
    pageContent_quote_BlockType: QuoteRender,
    // blogPostContent_video_BlockType: VideoRender,
  };

  const blocks = {
    blogPostContent_image_BlockType: ImageRender,
    blogPostContent_text_BlockType: TextRender,
    blogPostContent_quote_BlockType: QuoteRender,
    // blogPostContent_video_BlockType: VideoRender,
  };

  return (
    <>
      {data &&
        data?.map((block, index) => {
          const { __typename } = block;

          return React.createElement(
            microsite ? micrositeBlocks[__typename] : blocks[__typename],
            {
              key: index,
              data: block,
              box: boxData,
            }
          );
        })}
    </>
  );
};

export default Richtext;
