import React from "react";
import * as Yup from "yup";
import {
  Flex,
  Container,
  Paragraph,
  PrimaryInput,
  PrimaryButton,
  SubtitleMedium,
  TransparentTitle,
  SmallParagraph,
  MediumParagraph,
} from "../../layouts";
import {
  ContactFormContainer,
  DatePickerButton,
  ErrorMessage,
  ErrorMessageInput,
  EventDatePicker,
} from "./pageLayouts";
import "react-dropdown-now/style.css";
import { Dropdown } from "react-dropdown-now";
import { Formik, Form } from "formik";
import { useState } from "react";
import DatePicker from "react-datepicker";
import Icon from "../../assets/icons/icon";

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  surname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  address: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  postCode: Yup.number()
    .required("Required")
    .positive("Please add positive number")
    .integer("Please add valid number"),
  tel: Yup.number()
    .required("Required")
    .positive("Please add positive number")
    .integer("Please add valid number"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const Event = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const dummyDataArray = ["test1", "test2", "test3", "test4"];

  return (
    <Container>
      <ContactFormContainer>
        <Flex className="p-relative flex-column mb-10 pb-40">
          <TransparentTitle black className="pb-20">
            Anmeldeformular
          </TransparentTitle>
          <Paragraph className="pb-20">
            Füllen Sie bitte untenstehendes Formular aus, um sich für eines
            unserer Angebote anzumelden. Felder mit einem * müssen ausgefüllt
            werden.
          </Paragraph>
          <Paragraph>
            Die Kontaktinformationen werden ausschliesslich für die Bearbeitung
            Ihrer Anfrage verwendet. Datenschutzerklärung
          </Paragraph>
        </Flex>
        <Flex>
          <Formik
            initialValues={{
              name: "",
              surname: "",
              address: "",
              email: "",
              tel: "",
              message: "",
              postCode: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="w-full">
                <div className="w-full pb-20">
                  <SubtitleMedium className="mb-30">
                    Persönliche Angaben
                  </SubtitleMedium>
                  <Flex className="p-relative flex-column mb-10">
                    <label>Anrede*</label>
                    <Flex>
                      <Flex className="pr-20">
                        <label>
                          <PrimaryInput
                            type="radio"
                            id="gender"
                            name="gender"
                          />
                          Frau
                        </label>
                      </Flex>
                      <Flex>
                        <label>
                          <PrimaryInput
                            type="radio"
                            id="gender"
                            name="gender"
                          />
                          Herr
                        </label>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex className="mb-10 flex-wrap">
                    <Flex className="p-relative flex-column w-half pr-15 w-sm-full pr-sm-0 mb-sm-10">
                      <label for="surname">Vorname*</label>
                      <div className="p-relative w-full">
                        {errors.surname && touched.surname && (
                          <ErrorMessageInput />
                        )}
                        <PrimaryInput
                          black
                          id="surname"
                          type="text"
                          name="surname"
                          className="w-full"
                        />
                      </div>
                      {errors.surname && touched.surname && (
                        <ErrorMessage>{errors.surname}</ErrorMessage>
                      )}
                    </Flex>
                    <Flex className="p-relative flex-column w-half pl-15 w-sm-full pl-sm-0 mb-sm-10">
                      <label for="name">Name*</label>
                      <div className="p-relative w-full">
                        {errors.name && touched.name && <ErrorMessageInput />}
                        <PrimaryInput
                          black
                          id="name"
                          type="text"
                          name="name"
                          className={`w-full ${
                            errors.name ? "error-input" : ""
                          }`}
                        />
                      </div>
                      {errors.name && touched.name && (
                        <ErrorMessage>{errors.name}</ErrorMessage>
                      )}
                    </Flex>
                  </Flex>
                  <Flex className="mb-10 flex-wrap">
                    <Flex className="p-relative flex-column w-half pr-15 w-sm-full pr-sm-0 mb-sm-10">
                      <label for="address">Adresse</label>
                      <div className="p-relative w-full">
                        {errors.address && touched.address && (
                          <ErrorMessageInput />
                        )}
                        <PrimaryInput
                          black
                          id="address"
                          type="text"
                          name="address"
                          className="w-full"
                        />
                      </div>
                      {errors.address && touched.address && (
                        <ErrorMessage>{errors.address}</ErrorMessage>
                      )}
                    </Flex>
                    <Flex className="p-relative flex-column w-half pl-15 w-sm-full pl-sm-0 mb-sm-10">
                      <label for="postcode">PLZ/Ort</label>
                      <div className="p-relative w-full">
                        {errors.postCode && touched.postCode && (
                          <ErrorMessageInput />
                        )}
                        <PrimaryInput
                          black
                          id="postcode"
                          type="text"
                          name="postcode"
                          className="w-full"
                        />
                      </div>
                      {errors.postCode && touched.postCode && (
                        <ErrorMessage>{errors.postCode}</ErrorMessage>
                      )}
                    </Flex>
                  </Flex>
                  <Flex className="mb-10 flex-wrap">
                    <Flex className="p-relative flex-column pr-15 w-half w-sm-full pr-sm-0 mb-sm-10">
                      <label for="email">Email</label>
                      <div className="p-relative w-full">
                        {errors.email && touched.email && <ErrorMessageInput />}
                        <PrimaryInput
                          black
                          id="email"
                          type="email"
                          name="email"
                          className={`w-full ${
                            errors.email ? "error-input" : ""
                          }`}
                        />
                      </div>
                      {errors.email && touched.email && (
                        <ErrorMessage>{errors.email}</ErrorMessage>
                      )}
                    </Flex>
                    <Flex className="p-relative flex-column pl-15 w-half w-sm-full pl-sm-0 mb-sm-10">
                      <label for="tel">Telefon</label>
                      <div className="p-relative w-full">
                        {errors.tel && touched.tel && <ErrorMessageInput />}
                        <PrimaryInput
                          black
                          id="tel"
                          type="text"
                          name="tel"
                          className="w-full"
                        />
                      </div>
                      {errors.tel && touched.tel && (
                        <ErrorMessage>{errors.tel}</ErrorMessage>
                      )}
                    </Flex>
                  </Flex>
                  <Flex>
                    <Flex className="p-relative flex-column mb-40 w-half pr-15 w-sm-full pr-sm-0">
                      <label for="birth">Geburtsdatum*</label>
                      <PrimaryInput
                        black
                        id="birth"
                        type="text"
                        name="birth"
                        value={startDate}
                        readOnly={true}
                        className="w-full"
                      />
                      {errors.birth && touched.birth && (
                        <ErrorMessage>{errors.birth}</ErrorMessage>
                      )}
                    </Flex>
                    <Flex className="p-relative flex-column mb-40 w-half pl-15 justify-end">
                      <DatePickerButton onClick={handleClick}>
                        <Icon icon="datePicker" />
                      </DatePickerButton>
                      {isOpen && (
                        <EventDatePicker>
                          <DatePicker
                            dateFormat="yyyy/MM/dd"
                            onChange={handleChange}
                            inline
                          />
                        </EventDatePicker>
                      )}
                    </Flex>
                  </Flex>
                </div>
                <div className="p-relative pb-20">
                  <SubtitleMedium className="pb-30">
                    Rauchstopp-Angebot
                  </SubtitleMedium>
                  <Flex className="flex-column pb-30">
                    <MediumParagraph className="pb-10">
                      Motivationsabend
                    </MediumParagraph>
                    <Dropdown
                      className="w-full"
                      placeholder="Ort und Datum auswählen ..."
                      options={dummyDataArray}
                      value=""
                      // onChange={(value) => changeSelectedCity(value)}
                    />
                  </Flex>
                  <Flex className="flex-column pb-30">
                    <MediumParagraph className="pb-10">
                      Einzelberatung
                    </MediumParagraph>
                    <Dropdown
                      className="w-full mb-10"
                      placeholder="Ort auswählen ..."
                      options={dummyDataArray}
                      value=""
                      // onChange={(value) => changeSelectedCity(value)}
                    />
                    <Paragraph>
                      Nach Ihrer Anmeldung erhalten Sie von uns einen
                      Terminvorschlag.
                    </Paragraph>
                  </Flex>
                  <Flex className="flex-column pb-30">
                    <MediumParagraph className="pb-10">
                      Gruppenkurs
                    </MediumParagraph>
                    <Dropdown
                      className="w-full"
                      placeholder="Ort und Datum auswählen ..."
                      options={dummyDataArray}
                      value=""
                      // onChange={(value) => changeSelectedCity(value)}
                    />
                  </Flex>
                </div>
                <PrimaryButton
                  type="submit"
                  disabled={isSubmitting}
                  className="mb-40"
                  width="400px"
                >
                  Absenden
                </PrimaryButton>
              </Form>
            )}
          </Formik>
        </Flex>
      </ContactFormContainer>
    </Container>
  );
};

export default Event;
