import styled from "@emotion/styled";
import { Mobile, Tablet } from "../../../assets/mixins/_mixins";
import { Colors, Fonts, Transitions } from "../../../assets/variables/variables";
import { LineHeightCalc } from "../../../helpers/functions";

export const SingleContent = styled.div`
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  p,
  q,
  h1,
  h2,
  h3,
  h4,
  h5,
  img,
  span,
  video,
  strong,
  ul {
    padding-bottom: 40px;
    display: block;
  }

  a {
    color: inherit;
    text-decoration: underline; 
  }

  q, blockquote {
    font-family: ${Fonts.$extraLight};
    line-height: ${LineHeightCalc(37.4, 34)};
    font-size: 34px;
    padding-bottom: 40px;
    font-weight: 100;
    quotes: "«" "»" "‘" "’";

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }
  }

  blockquote {
    margin: 0;
  }

  img {
    max-width: 120%;
  }

  p {
    font-family: ${Fonts.$medium};
    font-size: 18px;

    a {
      color: inherit;
      text-decoration: underline;
    }

    q {
      padding-bottom: 0;
    }
  }

  h3 {
    line-height: ${LineHeightCalc(40, 34)};
    font-family: ${Fonts.$extraLight};
    font-weight: 100;
  }

  button:not(.test-pagination button) {
    padding: 10px 30px;
    border-radius: 200px;
    // background-color: ${Colors.$colorPurple};
    color: #fff;
    outline: none;
    border: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: ${Fonts.$light};
    font-size: 18px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: 0.2s ${Transitions.$linearSmooth};

    &:hover {
      background-color: ${Colors.$colorPrimary};
    }

    &:active {
      background-color: #fff;
      color: #000;
    }
  }

  ${Tablet} {
    max-width: 510px;
  }

  ${Mobile} {
    max-width: initial;
  }
`;

export const SinglePostTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;

  p {
    margin-bottom: 20px;
    font-size: 14px !important;
    font-family: ${Fonts.$light};
    padding-bottom: 0 !important;
  }
`;

export const LikeContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  svg {
    margin-bottom: 20px;
  }

  svg,
  svg path {
    transition: 0.3s ${Transitions.$linearSmooth};
  }

  p {
    &:hover {
      background-color: transparent;
    }
  }

  &:active {
    svg {
      transform: scale(1.3);
    }
  }

  &:hover {
    p,
    svg path {
      fill: ${Colors.$colorPrimaryLight};
      color: ${Colors.$colorPrimaryLight};
    }
  }
`;

export const SingleTeaser = styled.div`
  display: inline-block;

  p {
    display: inline-block;
  }

  span {
    padding-bottom: 0;
    display: inline-block;
    position: relative;
  }
`;

export const SingleContentShape = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
`