import styled from "@emotion/styled";
import { Mobile, Tablet } from "../../../assets/mixins/_mixins";

export const CategoryColorShape = styled.div`
  width: 900px;
  height: 700px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${Tablet} {
    width: 600px;
    height: 450px;
  }

  ${Mobile} {
    width: 400px;
    height: 340px;
  }
`