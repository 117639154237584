import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useQuery } from "@apollo/client";
import { Container, Flex, TitleTag, TransparentTitle } from "../../../layouts";
import Card from "../card";
import {
  FilterBtn,
  NavTag,
  SearchInput,
  SearchInputIcon,
  TagsNavigation,
} from "./layouts";
import Icon from "../../../assets/icons/icon";
import { GET_SEARCHED_POSTS } from "../../../data/data";
import Pagination from "../pagination";

function getPostsTags(data) {
  const tags = [];
  if (data) {
    data.blogEntries.forEach((entry) => {
      entry.blogPostTags.forEach((tag) => {
        const { slug } = tag;
        const isTaginTags = tags.find((elem) => elem.slug === slug);
        if (!isTaginTags) {
          tags.push(tag);
        }
        return;
      });
    });
  }

  return tags;
}

const SearchResult = () => {
  const [filtersButton, setFiltersButton] = useState({
    filter: false,
    allTags: false,
  });
  const [animate, setAnimate] = useState(false);
  const [postsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const [postsTags, setPostsTags] = useState(null);
  const [postsData, setPostsData] = useState(null);
  const [postsFilterdData, setPostsFilteredData] = useState(postsData);
  const [activeFilters, setActiveFilters] = useState([]);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const { ref, inView } = useInView({
    threshold: 0.15,
  });

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  useEffect(() => {
    if (activeFilters.length > 0) {
      const filteredPosts = postsData.filter((post) =>
        post.blogPostTags?.some((blogTag) =>
          activeFilters.includes(blogTag.slug)
        )
      );

      setPostsFilteredData(filteredPosts);
    } else {
      setPostsFilteredData(postsData);
    }
  }, [activeFilters]);

  useEffect(() => {
    if (postsFilterdData) {
      setCurrentPosts(
        postsFilterdData.slice(indexOfFirstPost, indexOfLastPost)
      );
      setTotalPosts(postsFilterdData.length);
    }
  }, [postsFilterdData]);

  const { search } = window.location;
  const searchWord = new URLSearchParams(search).get("s");
  const imgSize = "small";

  const {
    data = false,
    loading,
    error,
  } = useQuery(GET_SEARCHED_POSTS, {
    variables: { searchWord, imgSize },
    onCompleted: (data) => {
      setPostsData(data.blogEntries);
      setPostsFilteredData(data.blogEntries);
      setPostsTags(getPostsTags(data));
    },
  });

  if (loading) return;
  if (error) return;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function openFilterNav() {
    setFiltersButton({
      filter: !filtersButton.filter,
      allTags: filtersButton.allTags,
    });
  }

  function filterDataByTags(selectedTag) {
    if (activeFilters.includes(selectedTag)) {
      const removedElementFilters = activeFilters.filter(
        (filter) => filter !== selectedTag
      );
      setActiveFilters(removedElementFilters);
    } else {
      setActiveFilters([...activeFilters, selectedTag]);
    }
  }

  function selectAllFilters() {
    setActiveFilters([]);
  }

  return (
    <>
      <Flex className="flex-column mb-100 mb-sm-50 mt-70 mt-sm-50">
        <div className="p-relative d-block mb-30 mb-sm-50">
          <TransparentTitle
            ref={ref}
            primary
            black
            className={`${animate ? "io io-active" : "io"} pr-large mb-30`}
          >
            Suchresultate für {`<<${searchWord}>>`}
          </TransparentTitle>
          <Flex
            ref={ref}
            className={`${
              animate ? "io io-active" : "io"
            } w-half w-lg-full flex-column pr-15 pr-xl-0`}
          >
            <Flex className="p-relative mb-30">
              <form action="/search" method="get" className="w-full">
                <SearchInput
                  type="text"
                  name="s"
                  placeholder="Neue Suche"
                  width="100%"
                />
                <SearchInputIcon>
                  <Icon icon="search" />
                </SearchInputIcon>
              </form>
            </Flex>
            <Flex className="w-full">
              <FilterBtn
                left
                onClick={openFilterNav}
                className={`${filtersButton.filter ? "active" : ""}`}
              >
                <Icon icon="arrowDown" />
                <Flex className="justify-between w-full">
                  <TitleTag black>Filter</TitleTag>
                  <div className="filter-count">
                    <span>{activeFilters.length}</span>
                  </div>
                </Flex>
              </FilterBtn>
              <FilterBtn
                allTag
                onClick={selectAllFilters}
                className={`all-tags ${
                  activeFilters.length === 0 ? "active" : ""
                }`}
              >
                <div className="check-box" />
                <TitleTag black>Alles</TitleTag>
              </FilterBtn>
            </Flex>
          </Flex>
          <Flex className="p-relative">
            <TagsNavigation className={filtersButton.filter ? "active" : ""}>
              {postsTags &&
                postsTags.map((tag, index) => {
                  const { title, slug } = tag;
                  const isActive = activeFilters.includes(slug);

                  return (
                    <NavTag
                      key={index}
                      onClick={() => filterDataByTags(slug)}
                      className={isActive ? "active" : ""}
                    >
                      <span />
                      {title}
                    </NavTag>
                  );
                })}
            </TagsNavigation>
          </Flex>
        </div>
        <Flex className="flex-column">
          <div className="row">
            {currentPosts ? (
              currentPosts.map((post) => {
                return (
                  <Card
                    animation
                    data={post}
                    key={post.id}
                    classList="col-12 col-md-6 col-lg-3 col-xl-3 mb-100 mb-sm-50"
                  />
                );
              })
            ) : (
              <Container>
                <TransparentTitle black className="py-70">
                  NO RESULT FOR SEARCHED WORD
                </TransparentTitle>
              </Container>
            )}
          </div>
          <div
            ref={ref}
            className={`${
              animate ? "io io-active" : "io"
            } row d-flex justify-center`}
          >
            {currentPosts && (
              <Pagination
                loading={loading}
                paginate={paginate}
                totalPosts={totalPosts}
                currentPage={currentPage}
                postsPerPage={postsPerPage}
              />
            )}
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default SearchResult;
