import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Icon from "../../../assets/icons/icon";
import { ScrollButton } from "./layouts";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  if (pathname === "/print") return;

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    showTopBtn && (
      <ScrollButton onClick={goToTop}>
        <Icon icon="scrollTop" />
      </ScrollButton>
    )
  );
};
export default ScrollToTop;
