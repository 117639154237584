import styled from "@emotion/styled";
import { Colors, Fonts, Transitions } from "../../../assets/variables/variables";
import { Laptop } from "../../../assets/mixins/_mixins";

export const SearchInput = styled.input`
  width: ${(props) => props.width};
  color: ${Colors.$colorGrey};
  border: 1px solid #000;
  background-color: transparent;
  padding: 10px 35px;
  outline: none;
  font-size: 16px;
  font-family: ${(props) => (props.black ? Fonts.$regular : Fonts.$light)};
`;

export const SearchInputIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const FilterBtn = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: calc(50% - 15px);
  padding: 5px 35px;
  height: 40px;
  border: 1px solid #000;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  ${(props) => (props.left ? "margin-right: 15px;" : "margin-left: 15px;")}

  p {
    font-size: 16px;
    padding-left: 20px;
  }

  svg {
    transition: 0.15s ${Transitions.$linearSmooth};
  }

  .filter-count {
    position: relative;
    color: #fff;
    width: 22px;
    height: 22px;
    border: 1px solid black;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    span { 
      position: relative;
      line-height: 1;
      font-family: ${Fonts.$light};
    }
  }

  &.all-tags {
    .check-box {
      position: relative;
      width: 22px;
      height: 22px;
      border: 1px solid black;

      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: 10px;
        width: 0;
        height: 1px;
        background-color: #fff;
      }

      &:before {
        right: -2px;
        transform: rotate(45deg);
      }

      &:after {
        left: -2px;
        transform: rotate(-45deg);
      }
    }

    &.active {
      .check-box {
        background-color: #000;

        &:before,
        &:after {
          width: 24px;
        }
      }
    }
  }

  &.active {
    svg {
      transform: rotateX(180deg);
    }
  }
`;

export const TagsNavigation = styled.div`
  display: none;
  width: 100%;
  z-index: 10;

  padding: 40px 30px 0 60px;
  background-color: #000;

  &.active {
    display: block;
  }

  ${Laptop} {
    padding: 20px 10px 0 20px;
  }
`;

export const NavTag = styled.span`
  position: relative;
  padding: 2px 5px 2px 25px;
  margin: 0 30px 40px 0px;
  display: inline-block;
  font-size: 14px;
  font-family: ${Fonts.$light};
  line-height: 1;
  color: #000;
  text-transform: uppercase;
  background-color: #fff;
  cursor: pointer;
  letter-spacing: 1px;

  &:before {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    left: 2px;
    top: 50%;
    background-color: #000;
    transform: translateY(-50%);
  }

  &.active {
    > span {
      position: absolute;
      width: 13px;
      height: 13px;
      left: 2px;
      top: 2px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 6px;
        width: 13px;
        height: 1px;
        background-color: #fff;
      }

      &:before {
        left: 0;
        transform: rotate(-45deg);
      }

      &:after {
        right: 0;
        transform: rotate(45deg);
      }
    }
  }

  ${Laptop} {
    margin: 0 20px 30px 0px;
  }
`;
