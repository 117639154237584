import styled from "@emotion/styled";
import "poly-fluid-sizing";
import {
  Breakpoints,
  Colors,
  Fonts,
  Transitions,
} from "../assets/variables/variables";
import { LineHeightCalc } from "../helpers/functions";
import { Laptop, Mobile } from "../assets/mixins/_mixins";

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1310px;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const TransparentTitle = styled.h1`
  font-family: ${Fonts.$bold};
  line-height: ${LineHeightCalc(71, 65)};
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${(props) => (props.black ? "#000" : "#fff")};

  span {
    position: relative;
    display: block;
    white-space: nowrap;

    &:last-child {
      padding-left: 65px;
    }

    ${Laptop} {
      &:last-child {
        padding-left: 40px;
      }
    }
  }
`;

export const TransparentSubTitle = styled.h2`
  font-family: ${Fonts.$bold};
  line-height: ${LineHeightCalc(61, 54)};
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${(props) => (props.black ? "#000" : "#fff")};
`;

export const MainTitle = styled.h3`
  font-family: ${Fonts.$extraLight};
  line-height: ${LineHeightCalc(37.4, 34)};
  font-weight: 100;
  color: #fff;
`;

export const Title = styled.h3`
  line-height: ${LineHeightCalc(37.4, 34)};
  font-family: ${Fonts.$extraLight};
  color: #000;
  ${(props) =>
    props.hypens &&
    "overflow: hidden; display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical;"};
`;

export const SubtitleMedium = styled.h4`
  font-family: ${Fonts.$medium};
  color: #000;
`;

export const Tag = styled.p`
  font-family: ${Fonts.$light};
  font-size: 10px;
  padding: 2px 10px;
  border-radius: 100px;
  cursor: pointer;
  background-color: ${(props) =>
    props.category
      ? Colors.$colorGreen
      : props.blue
      ? Colors.$colorGreen
      : props.black
      ? "#000"
      : "#fff"};
  color: ${(props) => (props.black || props.blue ? "#fff" : "#000")};
  transition: 0.2s ${Transitions.$superSmooth};

  &:hover {
    background-color: ${Colors.$colorPrimaryLight};
    color: #000;
  }
`;

export const TitleTag = styled.p`
  font-family: ${Fonts.$light};
  font-size: ${(props) => (props.font ? props.font : "18px")};
  color: ${(props) => (props.black ? "#000" : "#fff")};
`;

export const Paragraph = styled.p`
  font-size: 18px;
  line-height: ${LineHeightCalc(28, 18)};
  color: #000;
  ${(props) =>
    props.hypens &&
    "overflow: hidden; display: -webkit-box; -webkit-line-clamp: 8; -webkit-box-orient: vertical;"};
`;

export const MediumParagraph = styled.p`
  font-size: 18px;
  font-family: ${Fonts.$medium};
  line-height: ${LineHeightCalc(28, 18)};
  color: #000;
  ${(props) =>
    props.hypens &&
    "overflow: hidden; display: -webkit-box; -webkit-line-clamp: 8; -webkit-box-orient: vertical;"};
`;

export const SmallParagraph = styled.p`
  font-size: 16px;
  line-height: 25px;
`;

export const PrimaryInput = styled.input`
  width: ${(props) => props.width};
  border: 1px solid ${(props) => (props.black ? "#000" : "#fff")};
  background-color: ${(props) => (props.black ? "transparent" : "#fff")};
  padding: 10px 30px;
  color: ${Colors.$colorGrey};
  outline: none;
  font-size: 16px;
  font-family: ${(props) => (props.black ? Fonts.$regular : Fonts.$light)};
  position: relative;

  &.error-input {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      background-color: ${Colors.$colorError};
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 4px;
      background-color: red;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
`;

export const PrimaryTextArea = styled.textarea`
  width: ${(props) => props.width};
  border: 1px solid ${(props) => (props.black ? "#000" : "#fff")};
  background-color: ${(props) => (props.black ? "transparent" : "#fff")};
  padding: 10px 30px;
  color: ${Colors.$colorGrey};
  outline: none;
  resize: none;
  font-size: 16px;
  font-family: ${(props) => (props.black ? Fonts.$regular : Fonts.$light)};
`;

export const PrimaryButton = styled.button`
  width: ${(props) => props.width};
  padding: 10px 40px;
  color: #fff;
  border: none;
  border-radius: 200px;
  background-color: ${(props) =>
    props.green
      ? Colors.$colorGreen
      : props.orange
      ? Colors.$colorOrange
      : "#000"};
  font-size: 18px;
  font-family: ${Fonts.$light};
  transition: 0.3s ${Transitions.$superSmooth};
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);

  &:hover {
    color: #fff;
    background-color: ${Colors.$colorPrimary};
  }

  &:active {
    color: #000;
    background-color: #fff;
  }

  a {
    color: inherit;
  }
  
  ${Laptop} {
    width: initial;
  }
`;

export const ContentContainer = styled.div`
  max-width: 650px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
  z-index: 10;

  ${Mobile} {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
  }
`;

export const OrderedContent = styled.ol`
  padding-left: 1.6em;
  position: relative;
`;

export const OrderedContentTitle = styled.li`
  font-family: ${Fonts.$medium};
  list-style: number;
  padding-bottom: 25px;
  font-weight: 700;

  @media (max-width: ${Breakpoints.$extraSmall}) {
    font-weight: 500;
  }

  h4 {
    padding-left: 15px;
  }

  p,
  ul h5,
  ul li {
    font-family: ${Fonts.$regular};
    font-size: 18px;
    margin-left: -20px;
    padding-bottom: 20px;
    font-weight: 200;

    &:last-child {
      padding-bottom: 0;
    }
  }

  ul {
    padding-bottom: 20px;

    h5 {
      font-weight: 200;
      padding-bottom: 0;
    }

    li {
      margin-left: 0;
      list-style: disc;
      padding-bottom: 0;
    }
  }
`;

export const TermsConditionsConatiner = styled.div`
  padding: 140px 0;
  max-width: 640px;
  margin: 0 auto;

  @media (max-width: ${Breakpoints.$extraSmall}) {
    padding: 10px 0;
    padding-bottom: 70px;
  }
`;

export const SecurityInformation = styled.div`
  border: 1px solid #000;
  padding: 32px 40px 32px 32px;
  border-radius: 0px 50px 0px 0px;
  ${(props) =>
    props.microsite &&
    "background-color: #A5E1EB; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); border: none;"};

  h4 {
    padding-bottom: 30px;
  }

  button {
    cursor: pointer;

    a {
      color: #fff;
    }

    &:active {
      a {
        color: #000;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    padding-bottom: 36px;
  }

  @media (max-width: ${Breakpoints.$extraSmall}) {
    padding: 20px;
  }
`;
