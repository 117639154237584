import styled from "@emotion/styled";
import { Laptop, Mobile, Tablet } from "../../../assets/mixins/_mixins";
import { Colors, Fonts } from "../../../assets/variables/variables";

export const LogoContainer = styled.div`
  display: flex;
  align-items: flex-end;

  img {
    max-width: 300px;
    padding-right: 15px;

    ${Laptop} {
      max-width: 250px;
    }

    ${Tablet} {
      max-width: 200px;
    }

    ${Mobile} {
      padding-right: 5px;
      max-width: 170px;
    }
  }
`;

export const LogoText = styled.p`
  font-size: 50px;
  letter-spacing: 1px;
  line-height: 0.8;
  font-family: ${Fonts.$extraLight};
  color: ${Colors.$colorPrimary};

  ${Laptop} {
    font-size: 40px;
  }

  ${Tablet} {
    font-size: 32px;
  }

  ${Mobile} {
    font-size: 26px;
    font-family: ${Fonts.$light};
  }
`;
