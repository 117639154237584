import React, { useState, useEffect, useContext } from "react";
import { useInView } from "react-intersection-observer";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { ModalContext } from "../../../context/modalContext";
import Icon from "../../../assets/icons/icon";
import {
  Container,
  PrimaryButton,
  PrimaryInput,
  SmallParagraph,
} from "../../../layouts";
import { FooterContainer, SocialIconContainer } from "./layouts";
import {
  ADD_SUBSCRIBE_EMAIL,
  GET_GLOBAL_SET_DATA,
  GET_SUBSCRIBED_EMAILS,
} from "../../../data/data";
import { getGlobalSelectedData } from "../../../helpers/functions";
import { InputError } from "../../schlafapnoe/single-test/layouts";

const Footer = () => {
  const [animate, setAnimate] = useState(false);
  const { pathname } = useLocation();
  const { setModal } = useContext(ModalContext);
  const [emailAddress, setEmailAddress] = useState(null);
  const [emailError, setEmailError] = useState(false);

  const [registerEmail] = useLazyQuery(GET_SUBSCRIBED_EMAILS, {
    variables: { email: emailAddress },
    onCompleted: () => {
      subscribeEmail({ variables: { email: emailAddress } })
        .then(() => {
          setModal({ status: true, value: "success" });
        })
        .catch(() => {
          setModal({ status: true, value: "error" });
        });
    },
  });

  const [subscribeEmail] = useMutation(ADD_SUBSCRIBE_EMAIL);

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const { loading, error, data } = useQuery(GET_GLOBAL_SET_DATA);

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  if (pathname === "/print") return;
  if (loading) return;
  if (error) return;

  const footerData = getGlobalSelectedData(data, "footer_GlobalSet");
  const socialLinks = getGlobalSelectedData(data, "socials_GlobalSet");

  function handleSubmit(e) {
    e.preventDefault();
    if (emailAddress) {
      setEmailError(false);
      registerEmail();
    } else {
      setEmailError("Please write your email");
    }
  }

  function handleEmailChange(e) {
    const email = e.target.value;

    if (isValidEmail(e.target.value)) {
      setEmailAddress(email);
    } else {
      setEmailAddress(null);
    }
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <FooterContainer>
      <Container>
        <div className="row">
          <div
            ref={ref}
            className={`${
              animate ? "io-footer io-active" : "io-footer"
            } col-12 col-xl-4 col-lg-4 pb-lg-30`}
          >
            {footerData.footerLinks && (
              <ul className="pl-0 d-flex justify-lg-center flex-wrap d-sm-inline-block w-full">
                {footerData.footerLinks.map((link, index) => {
                  const { label, linkIntern, linkExtern } = link;
                  const url = linkIntern ? linkIntern[0].url : linkExtern;

                  return (
                    <li key={index} className="mb-15 mr-15 d-inline-block">
                      <a href={url}>
                        <SmallParagraph>{label}</SmallParagraph>
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div
            ref={ref}
            className={`${
              animate ? "io-footer io-active" : "io-footer"
            } col-12 col-xl-4 col-lg-4 d-flex flex-column align-center align-lg-center pb-md-30 align-sm-left`}
          >
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-column">
                {emailError && <InputError>{emailError}</InputError>}
                <PrimaryInput
                  width="250px"
                  type="Email"
                  placeholder="Email-Adresse"
                  className="mb-20"
                  onChange={handleEmailChange}
                  black
                />
              </div>
              <PrimaryButton submit className="mb-20" width="250px">
                BLOG ABONNIEREN
              </PrimaryButton>
            </form>
          </div>
          <div
            ref={ref}
            className={`${
              animate ? "io-footer io-active" : "io-footer"
            } col-12 col-xl-4 col-lg-4 d-flex justify-end justify-lg-center justify-sm-start`}
          >
            {socialLinks.socials.map((socialMedia, index) => {
              const { SocialLink, socialType } = socialMedia;

              return (
                <a key={index} href={SocialLink} target="_blank" rel="noreferrer">
                  <SocialIconContainer>
                    <Icon icon={socialType} />
                  </SocialIconContainer>
                </a>
              );
            })}
          </div>
        </div>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
