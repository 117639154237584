import {
  Breakpoints,
  Colors,
  Transitions,
} from "../../../assets/variables/variables";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

export const ButtonRotationAnime = keyframes`
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(70deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

export const CategorySliderButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  cursor: pointer;
  padding: 70px;
  max-width: 100%;

  svg path,
  h4 {
    transition: 0.2s ${Transitions.$superSmooth};
  }

  &:hover {
    svg path {
      stroke: ${Colors.$colorPrimaryMedium};
    }

    svg {
      animation: ${ButtonRotationAnime} 5s cubic-bezier(0.76, 0, 0.24, 1)
        infinite;
    }

    h4 {
      color: ${Colors.$colorPrimaryMedium};
    }
  }

  @media (max-width: ${Breakpoints.$medium}) {
    padding: 70px;
    h4 {
      font-size: 22px;
    }
  }

  @media (max-width: ${Breakpoints.$small}) {
    max-width: 168px;
    max-height: 168px;

    h4 {
      font-size: 20px;
    }
  }
`;

export const ActiveButtonShape = styled.div`
  width: calc((1300px - 655px) / 2);
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(calc(100% + 30px));

  &.button-shape {
    &-top {
      transform: translate(calc(100% + 30px), 0);
    }

    &-center {
      top: 50%;
      transform: translate(calc(100% + 30px), -50%);
    }
  }
`;
