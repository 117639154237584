import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/scss/style.scss";
import { ApolloProvider } from "@apollo/client/react";
import { ModalContext } from "./context/modalContext";
import client from "./config";
import Home from "./pages/general/home";
import Error from "./pages/general/error";
import Single from "./pages/general/single";
import Categories from "./pages/general/posts";
import Impressum from "./pages/general/impressum";
import SingleSchlafapnoe from "./pages/schlafapnoe/single";
import SingleRauchstopp from "./pages/rauchstopp/single";
import Header from "./components/general/header";
import HeaderSchlafapnoe from "./components/schlafapnoe/header";
import Footer from "./components/general/footer";
import BlogModal from "./components/general/modal-blog";
import ScrollToTop from "./components/general/scroll-button";
import HomeSchlafapnoe from "./pages/schlafapnoe/home";
import HomeRauchstopp from "./pages/rauchstopp/home";
import PrintPage from "./pages/schlafapnoe/print";
import Contact from "./pages/rauchstopp/contact";
import Event from "./pages/rauchstopp/event";

const BlogSite = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/category/:category" element={<Categories />} />
        <Route path="/category/tag/:tag" element={<Categories />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/posts/:slug" element={<Single />} />
        <Route path="/search" element={<Categories search />} />
        <Route path="/*" element={<Error />} />
      </Routes>
    </>
  );
};

const SchlafapnoeSite = () => {
  return (
    <>
      <HeaderSchlafapnoe />
      <Routes>
        <Route path="/" element={<HomeSchlafapnoe />} exact />
        <Route path="/print" element={<PrintPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/*" element={<Error />} />
        <Route path="/search" element={<Categories search />} />
        <Route path="/posts/schlafapnoe/:slug" element={<SingleSchlafapnoe />} />
      </Routes>
    </>
  );
};

const RauchstoppSite = () => {
  return (
    <>
      <Header microsite query="rauchstopp"/>
      <Routes>
        <Route path="/" element={<HomeRauchstopp />} exact />
        <Route path="/contact" element={<Contact />} />
        <Route path="/event" element={<Event />} />
        <Route path="/*" element={<Error />} />
        <Route path="/search" element={<Categories search />} />
        <Route path="/posts/rauchstopp/:slug" element={<SingleRauchstopp />} />
      </Routes>
    </>
  );
};

function App() {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [modal]);

  const Route = window.location.origin;
  // const Route = "http://localhost:3001";

  var Site = {};
  Site[process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_BLOG] = BlogSite();
  Site[process.env.REACT_APP_PROTOCOL + "www." + process.env.REACT_APP_BLOG] = BlogSite();
  Site[process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_MICROSITE_SCHLAFAPNOE] = SchlafapnoeSite();
  Site[process.env.REACT_APP_PROTOCOL + "www." + process.env.REACT_APP_MICROSITE_SCHLAFAPNOE] = SchlafapnoeSite();
  Site[process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_MICROSITE_RAUCHSTOPP] = RauchstoppSite();
  Site[process.env.REACT_APP_PROTOCOL + "www." + process.env.REACT_APP_MICROSITE_RAUCHSTOPP] = RauchstoppSite();
  Site["http://localhost:3000"] = BlogSite();
  Site["http://localhostb:3000"] = SchlafapnoeSite();
  Site["http://localhostc:3000"] = RauchstoppSite();

  return (
    <BrowserRouter>
      <ModalContext.Provider value={{ modal, setModal }}>
        <ApolloProvider client={client}>
          {Site[Route]}
          <Footer />
          <ScrollToTop />
          <BlogModal />
        </ApolloProvider>
      </ModalContext.Provider>
    </BrowserRouter>
  );
}

export default App;
