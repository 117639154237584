import React, { useEffect } from "react";
import Icon from "../../assets/icons/icon";
import {
  SingleTestImage,
  SingleTestContent,
  StepTestInputCopy,
  TestPrintContainer,
  SingleTestQuestion,
  StepTestInputHolder,
  SingleTestContainer,
  SingleTestContainerPrint,
  SingleTestContentQuestion,
  SingleTestConfirmButtonVisual,
  StepTestInputOptionRadioButton,
} from "../../components/schlafapnoe/single-test/layouts";
import Image from "../../components/general/Image";
import logoSrc from "../../assets/images/logo.png";

import {
  Container,
  Flex,
  Paragraph,
  SecurityInformation,
  TransparentSubTitle,
} from "../../layouts";

const PrintPage = () => {
  const printData = JSON.parse(localStorage.getItem("Test questions"));

  useEffect(() => {
    window.print();
  }, []);

  return (
    <Container>
      {printData ? (
        <>
          <Flex className="mb-70 mt-30">
            <Image source={logoSrc} />
          </Flex>
          <Flex className="flex-wrap justify-between">
            {printData &&
              printData.map((item, index) => {
                const {
                  measurment = false,
                  question,
                  questionValue,
                  typeOfQuestion,
                } = item;

                const iconType =
                  typeOfQuestion.charAt(0).toUpperCase() +
                  typeOfQuestion.slice(1);

                return (
                  <SingleTestContainerPrint key={index}>
                    <SingleTestContainer>
                      <Flex className="justify-between">
                        <SingleTestImage print>
                          <Icon icon={`step${iconType}`} />
                        </SingleTestImage>
                        <SingleTestContent print>
                          <SingleTestContentQuestion>
                            <SingleTestQuestion>{question}</SingleTestQuestion>
                            {measurment ? (
                              <Flex className="flex-column">
                                <StepTestInputHolder>
                                  <StepTestInputCopy>
                                    <p>{questionValue}</p>
                                  </StepTestInputCopy>
                                  <Paragraph>{typeOfQuestion}</Paragraph>
                                </StepTestInputHolder>
                                <SingleTestConfirmButtonVisual>
                                  <Icon icon="stepConfirm" />
                                </SingleTestConfirmButtonVisual>
                              </Flex>
                            ) : (
                              <Flex className="flex-column">
                                <StepTestInputHolder>
                                  <Flex className="pr-30 align-center">
                                    <StepTestInputOptionRadioButton checked />
                                    <Paragraph>{questionValue}</Paragraph>
                                  </Flex>
                                </StepTestInputHolder>
                              </Flex>
                            )}
                          </SingleTestContentQuestion>
                        </SingleTestContent>
                      </Flex>
                    </SingleTestContainer>
                    <Flex className="justify-center w-full pt-20">
                      <Flex className="align-center test-pagination">
                        <Paragraph>
                          {index + 1} / {printData.length}
                        </Paragraph>
                      </Flex>
                    </Flex>
                  </SingleTestContainerPrint>
                );
              })}
            <SingleTestContainerPrint>
              <SingleTestContainer>
                <Flex>
                  <SingleTestImage print>
                    <Icon icon="stepTestFinish" />
                  </SingleTestImage>
                  <SingleTestContent print>
                    <Flex className="flex-column">
                      <SingleTestContentQuestion>
                        <SingleTestQuestion>Test auswerten!</SingleTestQuestion>
                      </SingleTestContentQuestion>
                      <SingleTestConfirmButtonVisual>
                        <Icon icon="stepConfirm" />
                      </SingleTestConfirmButtonVisual>
                    </Flex>
                  </SingleTestContent>
                </Flex>
              </SingleTestContainer>
              <Flex className="justify-center w-full pt-20">
                <Flex className="align-center test-pagination">
                  <Paragraph>
                    {printData.length} / {printData.length}
                  </Paragraph>
                </Flex>
              </Flex>
            </SingleTestContainerPrint>
            <SingleTestContainerPrint>
              <TestPrintContainer>
                <SecurityInformation microsite>
                  <h4>Ihr Testergebnis</h4>
                  <p className="mb-40">
                    Ihre Angaben weisen darauf hin, dass Sie möglicherweise an
                    einem Schlafapnoe-Syndrom leiden.
                  </p>
                  <p>
                    Drucken Sie den Test aus und nehmen Sie ihn beim nächsten
                    Arztbesuch mit. Möchten Sie eine Pneumologin oder einen
                    Pneumologen in Ihrer Nähe finden?
                  </p>
                </SecurityInformation>
              </TestPrintContainer>
            </SingleTestContainerPrint>
          </Flex>
        </>
      ) : (
        <TransparentSubTitle>Test data are missing...</TransparentSubTitle>
      )}
    </Container>
  );
};

export default PrintPage;
