import styled from "@emotion/styled";
import { Mobile } from "../../assets/mixins/_mixins";

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0;
  min-height: calc(100vh - (160px + 245px));

  ${Mobile} {
    min-height: calc(100vh - (127px + 338px));
    > div {
      justify-content: flex-start;
    }
  }
`;
