import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  Container,
  Flex,
  Paragraph,
  PrimaryButton,
  SecurityInformation,
  SubtitleMedium,
  TransparentSubTitle,
} from "../../../layouts";
import {
  InputError,
  NextPrevBtn,
  SingleTestImage,
  SingleTestContent,
  StepTestInputText,
  TestPrintContainer,
  SingleTestQuestion,
  StepTestInputHolder,
  StepTestInputOption,
  SingleTestContainer,
  SingleTestConfirmButton,
  SingleTestContentQuestion,
} from "./layouts";
import Icon from "../../../assets/icons/icon";
import { CategorySliderButton } from "../../general/category-posts-slider/layouts";
import ActiveButton from "../../rauchstopp/active-button";

const SingleTest = ({ data, microsite }) => {
  const [activeStep, setStep] = useState(0);
  const [questionData, setQuestionData] = useState([]);
  const [questionInput, setQuestionInput] = useState(null);
  const [questionError, setQuestionError] = useState(false);
  const [questionValue, setQuestionValue] = useState("");
  const [resultsBox, setShowResultsBox] = useState(false);
  const [animate, setAnimate] = useState(false);
  const resultBoxRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  
  const Steps = data[0].testItems;
  const currentStep = Steps[activeStep];
  const { testType, question } = currentStep;
  const stepInput = currentStep.input && currentStep.input;
  const stepOptions = currentStep.options && currentStep.options;
  const typeOfQuestion = Steps[activeStep].type;
  const questionText = Steps[activeStep].question;
  const measurment = Steps[activeStep].input && Steps[activeStep].input.text;

  useEffect(() => {
    if (inView === true) {
      setAnimate(true);
    }
  }, [inView]);

  useEffect(() => {
    if (resultsBox) {
      scrollToResultBox();
    }
  }, [resultsBox]);

  function scrollToResultBox() {
    resultBoxRef.current.scrollIntoView({ behavior: "smooth" });
  }

  function setNextStep(event) {
    event.preventDefault();
    setQuestionError(false);

    if (questionInput) {
      if (activeStep < questionData.length) {
        questionData[activeStep] = questionInput;
        setQuestionData(questionData);
      } else {
        setQuestionData([...questionData, questionInput]);
      }
      proceedToNextStep();
    } else {
      setQuestionError(true);
    }
  }

  function proceedToNextStep() {
    setStep(activeStep + 1);
    setQuestionValue("");
    setQuestionInput(null);
  }

  function setQuestionInfo(e) {
    const value = e.target.value;

    setQuestionValue(value);
    setQuestionInput({
      measurment,
      question: questionText,
      typeOfQuestion: typeOfQuestion,
      questionValue: e.target.value,
    });
  }

  function showResultTextBox(e) {
    e.preventDefault();
    setShowResultsBox(true);
    scrollToResultBox();
  }

  function printTestResults(e) {
    e.preventDefault();
    const url = "/print";
    localStorage.setItem("Test questions", JSON.stringify(questionData));
    window.open(url, "_blank", "noopener,noreferrer");
  }

  console.log(Steps);

  return (
    <Container>
      <div className="row">
        <TransparentSubTitle black>Schlafapnoe Risikotest</TransparentSubTitle>
      </div>
      <div className="row pb-40 p-relative">
        {Steps && (
          <>
            <SingleTestContainer
              ref={ref}
              className={animate ? "io io-active" : "io"}
            >
              <Flex>
                <SingleTestImage>
                  <Icon icon={`step${testType}`} />
                </SingleTestImage>
                <SingleTestContent>
                  <SingleTestContentQuestion>
                    <SingleTestQuestion>{question}</SingleTestQuestion>
                    {stepInput ? (
                      <Flex className="flex-column">
                        {questionError && (
                          <InputError>Plese fill the input</InputError>
                        )}
                        <StepTestInputHolder>
                          <StepTestInputText
                            type="text"
                            name={typeOfQuestion}
                            value={questionValue}
                            onChange={(e) => setQuestionInfo(e)}
                            width="100%"
                          />
                          <Paragraph className="pb-0">
                            {stepInput.text}
                          </Paragraph>
                        </StepTestInputHolder>
                      </Flex>
                    ) : stepOptions.length > 0 ? (
                      <Flex className="flex-column">
                        {questionError && (
                          <InputError>Plese fill the input</InputError>
                        )}
                        <StepTestInputHolder>
                          <label className="pr-30">
                            <StepTestInputOption
                              type="radio"
                              value={stepOptions[0].text}
                              name={typeOfQuestion}
                              onChange={(e) => setQuestionInfo(e)}
                            />
                            <Paragraph className="pb-0">
                              {stepOptions[0].text}
                            </Paragraph>
                          </label>
                          <label>
                            <StepTestInputOption
                              type="radio"
                              value={stepOptions[1].text}
                              name={typeOfQuestion}
                              onChange={(e) => setQuestionInfo(e)}
                            />
                            <Paragraph className="pb-0">
                              {stepOptions[1].text}
                            </Paragraph>
                          </label>
                        </StepTestInputHolder>
                      </Flex>
                    ) : (
                      <></>
                    )}
                    {activeStep === Steps.length - 1 ? (
                      <SingleTestConfirmButton
                        type="submit"
                        onClick={showResultTextBox}
                      >
                        <Icon icon="stepConfirm" />
                      </SingleTestConfirmButton>
                    ) : (
                      <SingleTestConfirmButton
                        type="submit"
                        onClick={(e) => setNextStep(e)}
                      >
                        <Icon icon="stepConfirm" />
                      </SingleTestConfirmButton>
                    )}
                  </SingleTestContentQuestion>
                </SingleTestContent>
              </Flex>
            </SingleTestContainer>
            <Flex
              className={
                animate
                  ? "io io-active justify-center w-full pt-20"
                  : "io justify-center w-full pt-20"
              }
              ref={ref}
            >
              <Flex className="align-center test-pagination">
                <NextPrevBtn
                  className="mb-35"
                  disabled={activeStep + 1 === 1}
                  onClick={() => setStep(activeStep - 1)}
                >
                  <Icon icon="arrowLeft" />
                </NextPrevBtn>
                <Paragraph className="ml-20 mr-20">
                  {activeStep + 1} / {Steps.length}
                </Paragraph>
                <NextPrevBtn
                  className="mb-35"
                  disabled={activeStep + 1 === Steps.length}
                  onClick={(e) => setNextStep(e)}
                >
                  <Icon icon="arrowRight" />
                </NextPrevBtn>
              </Flex>
            </Flex>
            {microsite && (
              <>
                <div className="p-relative">
                  <Paragraph>
                    Fagerström KO, Schneider NG. Measuring nicotine dependence:
                    A review of the Fagerström Tolerance Questionnaire. J Behav
                    Med. 1989; 12:159-181.
                  </Paragraph>
                  <Paragraph>
                    Diese Auswertung enthält keinen Rückbezug auf Ihre
                    Personendaten.
                  </Paragraph>
                </div>
                <ActiveButton
                  position="center"
                  text="Jetzt Konkakt aufnehmen"
                />
              </>
            )}
          </>
        )}
        {resultsBox && (
          <TestPrintContainer ref={resultBoxRef}>
            <SecurityInformation microsite>
              <h4>Ihr Testergebnis</h4>
              <p>
                Ihre Angaben weisen darauf hin, dass Sie möglicherweise an einem
                Schlafapnoe-Syndrom leiden.
              </p>
              <p>
                Drucken Sie den Test aus und nehmen Sie ihn beim nächsten
                Arztbesuch mit. Bitte wenden Sie sich an Ihren Hausarzt oder direkt an die Lungenliga Bern.
              </p>
              <PrimaryButton green onClick={printTestResults}>
                TEST AUSDRUCKEN
              </PrimaryButton>
            </SecurityInformation>
          </TestPrintContainer>
        )}
      </div>
    </Container>
  );
};

export default SingleTest;
