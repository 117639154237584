import styled from "@emotion/styled";

export const SliderContainer = styled.div`
  position: relative;

  .swiper {
    &-wrapper {
      padding-bottom: 40px;
    }

    &-scrollbar {
      left: 50%;
      width: 200px;
      transform: translateX(-50%);
      height: 2px;

      &-drag {
        background-color: #000;
        border-radius: 3;
        height: 5px;
        top: -1px;
        cursor: pointer;
      }
    }
  }
`;

export const SliderBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  svg {
    width: 100%;
    height: 100%;
  }
`;
